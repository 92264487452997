.top-withdraw-inf-card {
    width: 100%;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
}

.top-withdraw-inf-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.top-withdraw-inf-row:last-child {
    margin-bottom: 0;
}

.top-withdraw-inf-label {
    font-size: 12px;
    color: #666;
}

.top-withdraw-inf-row:nth-child(2) {
    .top-withdraw-inf-label i {
        background: url(/cn/img/1wallet/00/lock.svg) no-repeat 100% 0;
        padding-right: 20px;
        display: inline-block;
        vertical-align: sub;
        width: 16px;
        height: 16px;
    }
    .top-withdraw-inf-amount {
        background: url(/cn/img/1wallet/00/arw2.svg) no-repeat 100% 50%;
    }
}

.top-withdraw-inf-subtext {
    display: block;
    font-size: 10px;
    color: #999;
}

.top-withdraw-inf-amount {
    font-size: 16px;
    color: #222;
    padding-right: 18px;
}

