#SelectArddress {
    .cover {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5;
        top: 0;
        left: 0;
    }
    .bottom {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        z-index: 10;
        background-color: white;
        padding: 8px 16px;
        box-shadow: 0 0 5px #f3f3f3;
    }
    //地区选择样式
    .AreaSelection {
        // background-color: white;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        // padding-bottom: 64px;
        background-color: white;
        animation: showUp 0.3s ease both 1;
        .Header__TOP {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid #d6d6d6;
        }
        .selectArr {
            display: flex;
            position: relative;
            overflow: hidden;
        }
        .containerCover {
            width: 100%;
            height: 40px;
            border: 1px solid #d6d6d6;
            border-left: none;
            border-right: none;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 9;
            pointer-events: none;
        }
        .container {
            flex-grow: 1;
            flex-basis: 0; //解决 flex-grow 内容撑开问题
            text-align: center;
            background-color: white;
            height: 224px;
            padding-top: 60px;
        }
    }
    @keyframes showUp {
        0% {
            opacity: 0;
            transform: translateY(100%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .swiper-container {
        height: 170px;
    }
}
.page {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
}

//swiper 样式
#AreaSelection {
    .swiper-slide {
        width: auto;
        display: flex;

        justify-content: center;
        align-items: center;
        color: #888888;
    }
    .swiper-slide-active {
        color: #2d353a;
        font-size: 16px;
    }
}

.ant-select-dropdown-menu {
    .ant-select-dropdown-menu-item {
        text-align: center;
        color: #222;
    }
}
