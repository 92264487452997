.alike-digital-coin-select .ant-select-selection--single {
    height: 45px;
    .ant-select-selection__rendered {
        line-height: 44px;
        .ant-select-selection-selected-value {
            img {
                margin-top: 5px;
            }
        }
    }
}

.alike-digital-coin-select.at-withdrawal-dialog-modal {
    .ant-select-selection__rendered {
        .ant-select-selection-selected-value {
            i { // the selected option displaying
                margin-top:10px;
                display:inline-block;
            }
        }
    }
}
