.tutorial-modal .ant-modal-content {
    text-align: center;
    border-radius: 16px;

    .ant-modal-header {
        background: #00a5fd;


        .ant-modal-title {
            color: #fff;
            font-size: 16px;
        }
    }

    .ant-carousel .slick-list {
        border-radius: 8px;
    }

    .tutorial-carousel {
        .slick-dots {
            bottom: -12px !important;
            z-index: 999;

            button {
                margin-right: 5px;
            }
        }
    }



    .ant-carousel .tutorial-carousel .slick-dots li.slick-active {
        background: transparent;
    }

    .ant-carousel .tutorial-carousel .slick-dots li.slick-active button {
        background: #000000bf;
        border-radius: 50%;
        height: 8px;
        width: 8px;
    }

    .ant-carousel .tutorial-carousel .slick-dots li {
        background: transparent;
    }

    .ant-carousel .tutorial-carousel .slick-dots li button {
        background: #666666;
        border-radius: 50%;
        height: 8px;
        width: 8px;
    }

}