.euro2024-bg {
    background-color: #000d3e;

    .euro2024-wrap {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: bold;
            color: #fff;
            margin-bottom: 0;
            font-size: 20px;
        }
    }

    .carousel-wraps {
        width: 100%;
        margin: 20px auto 50px;
        padding: 10px 0;
        cursor: pointer;
        border-radius: 8px;

        .euroCup-mathchDate {
            height: 120px;
            margin: 0 40px;

            .euro-tabs {
                height: 120px;

                .ant-tabs-bar {
                    display: none;
                }

                .ant-tabs-content {
                    width: 100%;
                    height: 120px;
                    padding: 0;

                    .ant-tabs-tabpane {
                        width: 100%;
                        white-space: nowrap;
                        height: 120px;
                    }
                }
            }

            .gutter-box {
                width: 190px;
                border-radius: 16px;
                margin: 0 8px;
                overflow: hidden;
                float: left;
            }
        }

        .anticon-left-circle {
            width: 40px;
            height: 40px;
            color: #f9bf4b;
            font-size: 40px;
            position: absolute;
            left: 10px;
            top: 50px;
        }

        .anticon-right-circle {
            width: 40px;
            height: 40px;
            color: #f9bf4b;
            font-size: 40px;
            position: absolute;
            right: 10px;
            top: 50px;
        }
    }

    .euro-notification {
        width: 100%;
        height: 48px;
        background-color: #012a65;
        margin-top: 50px;

        .euro-notice-wrap {
            height: 48px;
            overflow: hidden;

            .massCom-List-item {
                p {
                    line-height: 48px;
                    color: #fff;
                    padding-left: 10px;
                }
            }
        }
    }

    .euro-prize-area {
        width: 100%;
        background-color: #05249c;
        background-image: url("/cn/img/euro2024/Building@2x.png");
        background-size: contain;
        background-position: left bottom;
        background-repeat: no-repeat;
        padding: 60px 0;
        color: #fff;

        .euro-prize-area-content {
            width: 570px;
            margin: auto;

            .ant-col {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 20px;
            }
        }

        .event-title {
            width: 201px;
            line-height: 38px;
            background-color: #183bc5;
            border-radius: 10px;
            text-align: center;
            margin: 0 auto 60px;

            h2 {
                font-size: 20px;
            }
        }

        .statistics-number {
            color: #ffda20;
        }

        .statistics-text {
            border-bottom: 1px solid #2f4cbf;
            padding-bottom: 30px;
        }

        .prize-rule {
            height: 102px;
            color: #ffda20;

            div {
                height: 42px;
                cursor: pointer;

                span {
                    margin: 0 10px 0 15px;
                }

                img {
                    width: 42px;
                }
            }
        }

        .prize-round-main-wrapper {
            width: 570px;
            height: 598px;
            border-radius: 20px;
            overflow: hidden;
            border: 1px solid #999;

            .euro-active-main {
                width: 570px;
                height: 598px;
                overflow: hidden;

                &.ready {
                    background-image: url("/cn/img/euro2024/BG-StartGame.jpg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;

                    .waitTop {
                        width: 468px;
                        height: 191px;
                        margin: 70px auto 20px;
                        background-image: url("/cn/img/euro2024/Logo-Title_CN.png");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }

                    .waitBottom {
                        width: 402px;
                        height: 61px;
                        margin: 100px auto 20px;
                        background-image: url("/cn/img/euro2024/ComingSoon-CN.png");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }

                // ready & start,
                &.isGameStart_false {
                    background-image: url("/cn/img/euro2024/BG-StartGame.jpg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;

                    .redayTop {
                        width: 468px;
                        height: 191px;
                        margin: 30px auto 20px;
                        background-image: url("/cn/img/euro2024/Logo-Title_CN.png");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }

                    .redayBottom {
                        img {
                            width: 118px;
                            height: auto;
                            cursor: pointer;
                        }
                    }
                }

                &.isGameStart_true {
                    background-image: url("/cn/img/euro2024/BG-Gameplay_CN.jpg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;

                    .start-penalty-kick {
                        position: relative;

                        img {
                            width: 100%;
                            height: auto;
                            cursor: pointer;

                            &:last-child {
                                position: absolute;
                                left: 0;
                            }
                        }
                    }

                    .winning-gif > img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .ramain-time-wrapper {
                    width: 100%;

                    .remainder-time-wrap {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        padding: 0 20px;

                        .remainder-item {
                            display: flex;
                            align-items: center;
                        }

                        .date-count-wrap {
                            width: 52px;
                            height: 76px;
                            color: #05249c;
                            background-color: #ffda20;
                            border-radius: 10px;
                            border: 2px solid #fff;
                            box-sizing: border-box;
                            text-align: center;
                            line-height: 76px;
                            margin: 0 3px;
                            font-size: 44px;
                            font-weight: bold;
                        }
                    }

                    .date-text {
                        line-height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        padding: 0 20px;
                    }

                    .activity-remainder-time {
                        width: 378px;
                        margin: 30px auto;
                        line-height: 36px;
                        background-color: #ffda20;
                        border-radius: 20px;
                        text-align: center;
                        font-size: 14px;
                        color: #000;
                    }
                }
            }
        }
    }

    .euro-more-promotion-area {
        width: 1200px;
        margin: auto;
        text-align: center;
        padding: 60px 0;

        .euro-more-promotion-area-content {
            .event-title {
                margin: 0 auto 60px;
            }

            .ant-col-6 {
                cursor: pointer;
                .gutter-box {
                    min-height: 180px;
                    background: linear-gradient(
                        90deg,
                        #ddd 25%,
                        #ccc 37%,
                        #ddd 63%
                    );
                    background-size: 400% 100%;
                    -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
                    animation: ant-skeleton-loading 1.4s ease infinite;
                    border-radius: 16px;
                    overflow: hidden;
                }

                .gutter-temp {
                    min-height: 180px;
                    background: linear-gradient(
                        90deg,
                        #ddd 25%,
                        #ccc 37%,
                        #ddd 63%
                    );
                    background-size: 400% 100%;
                    -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
                    animation: ant-skeleton-loading 1.4s ease infinite;
                    border-radius: 16px;
                }
            }
        }
    }
}

.euro2024-terms-and-conditions {
    .ant-modal-header {
        background-color: #143cdb;
        border-radius: 26px 26px 0 0;
        height: 60px;

        .ant-modal-title {
            color: #fff;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
        }
    }

    .ant-modal-content {
        border-radius: 26px;
        color: #000;
        font-size: 16px;
        background-color: #f5f7fa;
        overflow: hidden;

        .ant-modal-close-x {
            font-size: 26px;
            margin-top: 3px;
            color: #fff;
        }
    }

    .ant-modal-body {
        padding: 0;
        height: 600px;

        .midautumn-direction-container {
            padding: 30px 50px;
            height: 600px;
            overflow-y: scroll;
        }

        .details-list {
            margin-bottom: 10px;
            box-sizing: border-box;

            .ant-col-24 {
                background-color: #143cdb;
                line-height: 32px;
                text-align: center;
                color: #fff;

                > .ant-col-7,
                > .ant-col-5 {
                    height: 32px;
                    line-height: 32px;
                    color: #fff;
                }
            }

            .ant-col-7,
            .ant-col-5 {
                color: #000;
                height: 130px;
                line-height: 130px;
                border-left: 2px solid #143cdb;
                border-bottom: 2px solid #143cdb;
                text-align: center;

                .ant-col-24 {
                    background-color: #fff;
                    color: #000;
                    line-height: 24px;
                    border-bottom: 2px solid #143cdb;
                    box-sizing: border-box;
                }
            }

            .ant-col-5 {
                &:last-child {
                    border-right: 2px solid #143cdb;
                }
            }
            .col-times{
                display: flex;
                line-height: 20px;
                align-items: center;
            }
        }
        .currency-list li img{
            width: 16px;
            height: auto;
        }
        .t-content{
            position: relative;
            overflow: hidden;
            padding: 60px;
            height: 600px;
            .t-header{
                left: 60px;
                top: 60px;
                width: calc(100% - 120px);
                position: absolute;
                z-index: 1;
            }
            .ant-col-24 {
                color: #fff;
                background-color: #647ad4;
                line-height: 37px;
                text-align: center;
            }
        }
        .record-history {
            overflow: auto;
            font-size: 14px;
            width: 100%;
            height: 450px;
            margin-top: 37px;
            .prize-list {
                color: #1a313c;

                &:nth-child(odd) {
                    background-color: #d9ddec;
                }

                &:nth-child(even) {
                    background-color: #ccd1e5;
                }
            }

            .no-prize-list {
                color: #1a313c;
                margin-top: 10px;
                background-color: #d9ddec;
            }
        }

        .footballTeam {
            padding: 20px;
            width: 100%;

            .ant-col-24 {
                width: 100%;
                line-height: 47px;
                background-color: #fee5e5;
                border-radius: 4px;
                color: #eb2121;
                padding-left: 10px;
                margin-bottom: 10px;
            }

            .ant-col-8 {
                height: 40px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                flex: 1;
                padding-left: 5px;

                .ant-checkbox-wrapper {
                    img {
                        width: 24px;
                        height: 24px;
                        margin: 0 5px 0 0;
                    }

                    .ant-checkbox-inner {
                        border-radius: 2px;
                    }
                }
            }

            .ant-col-11 {
                margin-top: 10px;
                margin-bottom: 10px;

                .ant-btn {
                    width: 100%;

                    &.ant-btn {
                        border: 1px solid #143cdb;
                        color: #143cdb;
                    }
                }

                &:last-child .ant-btn {
                    background-color: #143cdb;
                    color: #fff;
                }
            }
        }

        .empty-footballTeam {
            padding: 20px;
            width: 100%;
            height: 100%;
            text-align: center;
            background: linear-gradient(
                90deg,
                #f2f2f2 25%,
                #e6e6e6 37%,
                #f2f2f2 63%
            );
            background-size: 400% 100%;
            -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
            animation: ant-skeleton-loading 1.4s ease infinite;
        }
    }

    // 定义Antd Spin loading 状态背景色
    .ant-spin-nested-loading > div > .ant-spin {
        background: transparent;
    }

    &.supportTeam .ant-modal-body {
        height: auto;
    }
}

.euro-event-end {
    width: 600px;

    .ant-modal-content {
        background-color: transparent;
        box-shadow: none;
    }

    .ant-modal-close-x {
        display: none;
    }

    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-confirm-content {
        margin: 0;
    }

    .content {
        width: 100%;
        height: 500px;
        background-color: #fff;
        border-radius: 20px;
        overflow: hidden;
        position: relative;

        img {
            width: 600px;
            height: auto;
        }

        .okbtn {
            width: 300px;
            line-height: 40px;
            position: absolute;
            left: calc(300px - 150px);
            bottom: 30px;
            cursor: pointer;
            background-color: @theme;
            border-radius: 8px;
            color: #fff;
        }
    }

    .ant-modal-confirm-btns {
        display: none;
    }
}

.ant-modal-confirm.howtoplay-modal-info {
    width: 440px;

    .ant-modal-content {
        background-color: transparent;
        box-shadow: none;
    }

    .ant-modal-close-x {
        display: none;
    }

    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-confirm-content {
        margin: 0;
    }

    .howtoplay {
        width: 440px;
        height: 440px;
        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        .okbtn {
            width: 160px;
            position: absolute;
            left: calc(220px - 80px);
            bottom: 40px;
            cursor: pointer;
        }
    }

    .ant-modal-confirm-btns {
        display: none;
    }
}

.euro-info-dialog {
    width: 490px;
    .ant-modal-close{
        display: none;
    }
    .ant-modal-content {
        border-radius: 16px;
        overflow: hidden;
    }

    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-confirm-body .ant-modal-confirm-title {
        text-align: center;
        line-height: 60px;
        font-size: 18px;
        background-color: #143cdb;
        color: #fff;
        font-weight: 600;
    }

    .ant-modal-confirm-body
        > .anticon
        + .ant-modal-confirm-title
        + .ant-modal-confirm-content {
        margin-left: 0;
    }

    .ant-modal-confirm-content {
        margin-left: 0;
        text-align: center;
        padding: 15px 30px;
        font-size: 16px;
        color: #1a313c;
    }

    .ant-modal-confirm-btns {
        float: none;
        margin-bottom: 34px;

        .ant-btn {
            width: 170px !important;
            height: 48px;
            line-height: 48px;
            font-size: 18px;
            margin-left: 30px;

            &:first-child {
                border: 2px solid #143cdb;
                color: #143cdb;
                margin-left: 0;
            }

            &:last-child {
                background-color: #143cdb;
            }

            &.ant-btn-primary {
                color: #fff;
            }
        }
    }
}

.euro2024-prize-popup {
    width: 500px;
    height: 500px;
    padding: 0;
    background-image: url("/cn/img/euro2024/PopUpPrize_CN.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .ant-modal-content {
        border-radius: 26px;
        overflow: hidden;
        background-color: transparent;
        box-shadow: none;
    }

    .ant-modal-close {
        display: none;
    }

    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-confirm-body
        > .anticon
        + .ant-modal-confirm-title
        + .ant-modal-confirm-content {
        margin-left: 0;
    }

    .ant-modal-confirm-content {
        margin-left: 0;
        text-align: center;
        padding: 15px 30px;
        font-size: 16px;
        color: #1a313c;

        img {
            width: 200px;
            height: auto;
        }

        .prize-popup-content-text {
            font-size: 20px;
            color: #1a313c;
            margin: 5px 0;
            span {
                color: #143cdb;
                font-weight: bold;
            }
        }
        .ann14-prize-popup-content-text-highlight{
            color: #143CDB;
            font-weight: bold;
        }
    }
    &.no-prize-popup .ant-modal-confirm-content{
        margin: 200px 0 50px;
        .highlight{
            color: @theme;
        }
    }
    &.one-btn .ant-modal-confirm-btns {
        width: 170px;
        margin: auto;
    }
    .ant-modal-confirm-btns {
        float: none;
        margin-bottom: 34px;

        .ant-btn {
            width: 170px;
            height: 48px;
            line-height: 48px;
            font-size: 18px;
            margin-left: 30px;

            &:first-child {
                border: 2px solid #143cdb;
                color: #143cdb;
                margin-left: 0;
            }

            &:last-child {
                background-color: #143cdb;
            }

            &.ant-btn-primary {
                color: #fff;
            }
        }
    }
}
