.heading-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 33px;
    gap: 30px;

    img {
        height: max-content;
    }
    .usdt-weight-title {
        color: #ffe4c4;
        font-size: 35px;
        font-weight: 300;
        margin: 0;
    }
}
