.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.pagination-button {
    background-color: transparent;
    border: none;
    border-radius: 4px;
    padding: 4px 10px;
    cursor: pointer;
    color: #666;
}

.pagination-button.active {
    border: 1px solid #00A6FF;
    color: #00A6FF;
}

.pagination-button:hover {
    border-color: #00A6FF;
}

.pagination-arrow {
    background-color: transparent;
    border: none;
    color: #666;
    font-size: 16px;
    cursor: pointer;
}

.pagination-arrow.disabled {
    color: #ccc;
    cursor: not-allowed;
}

