.live-server-btn-wrap {
    .live-server-btn {
        margin-left: 18px;
        display: inline-block;
        padding: 0 16px 0 6px;
        line-height: 34px;
        height: 34px;
        background-color: #fff;
        border-radius: 20px;
        color: #222;
        font-weight: bold;
        box-shadow: 0 3px 3px #131313;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        cursor: pointer;

        .live-service {
            border-radius: 50%;
            vertical-align: -0.8em;
            background-position: 0 -81px;
            transition: all 0.2s;
            cursor: pointer;
        }

        span {
            vertical-align: middle;
        }
    }
}
