.mine-promo {
    .lst {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        // align-items: flex-start;
        // align-content: flex-start;
    }
}

/* these CSS should move to ./UI/box.less */
.message-list,
.mine-promo {
    .box {
        border-radius: 6px;
        background: #fff;
        margin: 0 16px 16px 0;
        padding: 16px 16px 0;
        position: relative;
    }
    .box:nth-child(3n) {
        margin-right: 0;
    }
    .box .hd {
        color: #222;
        font-size: 16px;
        font-weight: bold;
        height: 44px;
        margin: 0 auto 12px;
    }
    .box .bd {
        border-bottom: 1px solid #E3E3E3;
        padding-bottom: 4px;
    }
    .box .bd-row {
        margin: 0 0 12px;
        clear: both;
        color: #666;
        font-size: 12px;
    }
    .box .bd-row:first-child {
        font-size: 14px;
        .bd-row-r {
            font-size: 18px;
            color: #222;
        }
    }
    .box .hd-r,
    .box .bd-row-r {
        float: right;
        &.bd-row-r-money {
            font-weight: bold;
        }
    }

    .box .hd-r-cancel {
        display: none;
        position: absolute;
        top:40px;
        right:16px;
        color:#666;
        background: #fff;
        border:1px solid #E3E3E3;
        border-radius:6px;
        box-shadow:1px 4px 6px rgba(0, 0, 0, 0.25);
        width:160px;
        height:52px;
        div {
            text-indent: 8px;
            font-weight: 600;
            width:144px;
            height:36px;
            line-height:36px;
            text-align: left;
            margin:8px;
            border-radius:6px
        }
    }
    .box .hd-r-cancel:hover {
        div {
            background: #efeff4;
        }
    }
    .box .ft {
        margin: 8px 0;
    }

    .box .box-compl .bd {
        border-bottom: none;
    }
    .box .box-compl .bd-row-r {
        cursor: pointer;
    }
}

.mine-promo {
    .box {
        width: calc((100% - 32px) / 3);
    }
    .box .ft {
        background: #E3E3E3;
        display: flex;
        justify-content: space-between;
        gap: 1px;
        margin: 8px;
        a {
            background: #fff;
            text-align: center;
            display: inline-block;
            flex: 1;
            color: #666;
            font-size: 16px;
            line-height: 36px;
            height: 36px;
        }
        a:last-child {
            color: #00A6FF;
        }
    }
}

.message-list {
    .box {
        width:362px;
        border:1px solid #E3E3E3;
        border-radius: 6px;
    }
    .box .ft {
        text-align: left;
    }
    .inbox-item {
        width: 100%;
    }
    .inbox-item-r {
        float: right;
        margin-right:-67px;
    }
    .inbox-item-l {
        float: left;
        max-width: 500px;
        .box {
            margin-bottom: 0;
        }
    }
}
