.mdl2025 {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    z-index:9999; /* because sticky header is 999 */
    top: 0;
    left: 0;
}

.mdl2025-container {
    max-height: 90vh;
    position: absolute;
    overflow-x: hidden;
    position: relative;
    background-color: white;
    padding: 44px 40px 36px;
    border-radius: 8px;
    top:170px;
    left:50%;
    width:287px;
    margin-left:-143px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transform: scale(.7);
    transition: transform .25s ease-in-out, opacity .25s ease-in-out;
}
.mdl2025-x {
    position: absolute;
    top:16px;
    right: 16px;
    cursor: pointer;
}

.mdl2025-title {
    font-size: 16px;
    font-weight: bold;
    color:#222;
    margin-bottom: 10px;
}
.mdl2025-message {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
    .first-ico {
        display:block;
        width:40px;
        height: 40px;
        margin:20px auto;
    }
}

.mdl2025-button {
    width: 100%;
    height:38px;
    font-size: 16px;
    line-height: 16px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
}

.mdl2025-btns:not(.mdl2025-btns-full-w) {
    display:flex;
    justify-content: space-between;
    gap:10px;
    flex-direction:row-reverse;
    button {
        flex: 1;
        text-align: center;
    }
}
.mdl2025-btns-full-w {
    margin-top:20px;
    button {
        width: 100%;
    }
}

.mdl2025-button.primary {
    background-color: #00A6FF;
    color: white;
}
.mdl2025-button.primary[disabled] {
    background-color: #e3e3e3;
    color: #999;
}

.mdl2025-button.secondary {
    background-color: white;
    color: #00A6FF;
    border: 1px solid #00A6FF;
}

@mdl2025-btn-height: 48px;
.mdl2025.WIDE {
    .mdl2025-container {
        width:440px;
        margin-left:-220px;
    }
    .mdl2025-title {
        font-size:20px;
    }
    .mdl2025-message {
        font-size:16px;
        min-height: 32px;
    }
    .mdl2025-btns:not(.mdl2025-btns-full-w) {
        height:@mdl2025-btn-height;
    }
    .mdl2025-button {
        height:@mdl2025-btn-height; // for .mdl2025-btns-full-w case, we also need to assign the button height here.
        line-height:2;
    }
}
.mdl2025.NEAR_W {
    .mdl2025-container {
        width:426px;
        margin-left:-213px;
    }
}

