.DailyGiftAddressDetail {
    width: 400px !important;
    // height: 778px;

    .ant-modal-title {
        color: #fff;
    }

    .ant-modal-body {
        padding: 0 30px 30px 30px;
    }

    .DailyGiftAddressDetail-text {
        display: flex;
        align-items: center;
        width: 340px;
        padding-left: 16px;
        /* UI Properties */
        background: #fff5bf 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal bold 12px/20px Microsoft YaHei;
        letter-spacing: 0px;
        color: #83630b;
        opacity: 1;
        margin-top: 30px;
    }
    .item-text {
        height: 20px;
        /* UI Properties */
        text-align: left;
        margin-left: 4px;
        letter-spacing: 0px;
        color: #222222;
        opacity: 1;
        margin-bottom: 10px;
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-Regular;
        font-weight: bold;
    }
    .ant-checkbox-inner {
        border-radius: 0;
    }
    .ant-select-selection--single {
        height: 45px;
    }
    .DailyGiftAddressDetail-btn {
        display: flex;
        justify-content: center;
        margin-top: 31px;
        button {
            width: 340px;
            height: 40px;
            padding: 10px 0;
            border: 0;
            background: #00a6ff 0% 0% no-repeat padding-box;
            border-radius: 8px;
            opacity: 1;
            text-align: center;
            letter-spacing: -2px;
            color: #ffffff;
            opacity: 1;
            font-size: 14px;
            font-family: MicrosoftYaHei-Bold;
            font-weight: bold;
        }
        button:hover {
            color: #ffffff;
        }
    }

    // select > option 樣式
    .ant-select-selection__rendered {
        display: flex;
        align-items: center;
        margin-left: 24px;
        line-height: 42px;
    }
    .ant-select-selection-selected-value {
        vertical-align: bottom;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #222222;
    }

    // prefix前綴 input 樣式
    .ant-input-affix-wrapper .ant-input-prefix {
        text-align: left;
        letter-spacing: 0px;
        color: #999999;
        opacity: 1;
    }
    .ant-input-affix-wrapper .ant-input:not(:first-child) {
        padding-left: 8px;
    }
    .prefixText.ant-input-affix-wrapper .ant-input:not(:first-child) {
        padding-left: 45px;
    }

    .ant-modal-content {
        border-radius: 20px;
    }

    .ant-modal-header {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .input-error-message {
        margin-top: 10px;
    }

    .ant-select-disabled {
        .ant-select-selection-selected-value {
            color: rgba(0, 0, 0, 0.25);
        }
    }

    .lastname-input,
    .firstname-input {
        border: 1px solid #bfbfbf;
        border-radius: 10px;
    }
}
