.vipPages {
    width: 100%;
    background: url(/cn/img/vip/vipbg.jpg) no-repeat;
    min-height: 100vh;
    background-position: center 0;
    .vip-wrap {
        width: 100%;
        margin-top: 800px;
        color: #222222;
        .common-distance {
            padding: 0;
        }
        .line-lg-distance {
            height: 80px;
        }
        .public-title {
            width: 480px;
            height: 58px;
            background: url(/cn/img/vip/titlebg.png) center no-repeat;
            background-size: contain;
            color: #a67719;
            font-size: 24px;
            text-align: center;
            line-height: 58px;
            margin: auto;
            font-weight: 600;
        }
        .public-list {
            width: 100%;
            background: transparent
                linear-gradient(180deg, #fffaef 0%, #f5d7a5 100%) 0% 0%
                no-repeat padding-box;
            border-radius: 40px;
            box-shadow: 0px 1px 10px #00000029;
            padding: 40px;
            position: relative;
            overflow: hidden;
            .ant-select-lg {
                width: 100%;
            }
            .halftoneL {
                width: 210px;
                height: 63px;
                background: url(/cn/img/vip/halftoneL.png) center no-repeat;
                background-size: contain;
                position: absolute;
                left: 0;
                bottom: 0;
            }
            .halftoneR {
                width: 210px;
                height: 63px;
                background: url(/cn/img/vip/halftoneR.png) center no-repeat;
                background-size: contain;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
        .vip-level-details {
            width: 100%;
            min-height: 300px;
            background: transparent
                linear-gradient(180deg, #fffaef 0%, #f5d7a5 100%) 0% 0%
                no-repeat padding-box;
            border-radius: 40px;
            box-shadow: 0px 1px 10px #00000029;
            padding: 20px 40px;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            .iconImgRow {
                margin-bottom: 20px;
                .ant-col-2 {
                    width: 97px;
                    height: 97px;
                    filter: opacity(0.35);
                    position: relative;
                    div {
                        position: absolute;
                        left: 0;
                        top: 0;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        &.L001::after,
                        &.L002::after,
                        &.L003::after,
                        &.L004::after,
                        &.L005::after,
                        &.L006::after,
                        &.L007::after,
                        &.L008::after,
                        &.L009::after,
                        &.L0010::after {
                            content: "";
                            width: 20px;
                            height: 20px;
                            background-image: url(/cn/img/vip/redxing.png);
                            background-repeat: no-repeat;
                            background-size: cover;
                            position: absolute;
                            display: inline;
                            top: 0;
                            right: 0;
                            z-index: 10;
                        }
                    }
                }
                .L001,
                .L002,
                .L003,
                .L004,
                .L005,
                .L006,
                .L007,
                .L008,
                .L009,
                .L0010 {
                    filter: opacity(1);
                    div {
                        transform: scale(1.15);
                        transition: all 1s linear 0s;
                    }
                }
            }
            .infosRow {
                .ant-col-11 {
                    .levelInfoLeft {
                        width: 100%;
                        height: 160px;
                        border-radius: 10px;
                        &.L001,
                        &.L002,
                        &.L003,
                        &.L004 {
                            background: url(/cn/img/vip/vip1-3.png) center
                                no-repeat;
                            background-size: cover;
                            ul {
                                width: 100%;
                                text-align: center;
                                font-weight: bold;
                                padding: 0 20px;
                                li {
                                    &:nth-child(1) {
                                        width: 100%;
                                        line-height: 40px;
                                        text-align: left;
                                        background: url(/cn/img/vip/skyblue.png)
                                            0 10px no-repeat;
                                        background-size: 22px 17px;
                                        span {
                                            color: #7685bc;
                                            font-size: 16px;
                                            margin-left: 30px;
                                        }
                                    }
                                    &:nth-child(2) {
                                        width: 100%;
                                        line-height: 45px;
                                        font-size: 32px;
                                        color: #7685bc;
                                    }
                                    &:nth-child(3) {
                                        width: 100%;
                                        font-size: 19px;
                                        color: #7685bc;
                                    }
                                    &:nth-child(4) {
                                        width: 100%;
                                        font-size: 12px;
                                        color: #7685bc;
                                        span {
                                            &:first-child {
                                                float: left;
                                                font-weight: normal;
                                            }
                                            &:last-child {
                                                float: right;
                                                font-weight: 900;
                                            }
                                        }
                                    }
                                    &:nth-child(5) {
                                        .ant-progress-inner {
                                            background-color: #eee !important;
                                            .ant-progress-bg {
                                                height: 4px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.L005,
                        &.L006,
                        &.L007 {
                            background: url(/cn/img/vip/vip4-6.png) center
                                no-repeat;
                            background-size: cover;
                            ul {
                                width: 100%;
                                text-align: center;
                                font-weight: bold;
                                padding: 0 20px;
                                li {
                                    color: #8465b7;
                                    &:nth-child(1) {
                                        width: 100%;
                                        line-height: 40px;
                                        text-align: left;
                                        background: url(/cn/img/vip/purple.png)
                                            0 10px no-repeat;
                                        background-size: 22px 17px;
                                        span {
                                            font-size: 16px;
                                            margin-left: 30px;
                                        }
                                    }
                                    &:nth-child(2) {
                                        width: 100%;
                                        line-height: 45px;
                                        font-size: 32px;
                                    }
                                    &:nth-child(3) {
                                        width: 100%;
                                        font-size: 19px;
                                    }
                                    &:nth-child(4) {
                                        width: 100%;
                                        font-size: 12px;
                                        span {
                                            &:first-child {
                                                float: left;
                                                font-weight: normal;
                                            }
                                            &:last-child {
                                                float: right;
                                                font-weight: 900;
                                            }
                                        }
                                    }
                                    &:nth-child(5) {
                                        .ant-progress-inner {
                                            background-color: #eee !important;
                                            .ant-progress-bg {
                                                height: 4px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.L008,
                        &.L009,
                        &.L010 {
                            background: url(/cn/img/vip/vip7-10.png) center
                                no-repeat;
                            background-size: cover;
                            ul {
                                width: 100%;
                                text-align: center;
                                font-weight: bold;
                                padding: 0 20px;
                                li {
                                    color: #a67719;
                                    &:nth-child(1) {
                                        width: 100%;
                                        line-height: 40px;
                                        text-align: left;
                                        background: url(/cn/img/vip/apricot.png)
                                            0 10px no-repeat;
                                        background-size: 22px 17px;
                                        span {
                                            font-size: 16px;
                                            margin-left: 30px;
                                        }
                                    }
                                    &:nth-child(2) {
                                        width: 100%;
                                        line-height: 45px;
                                        font-size: 32px;
                                    }
                                    &:nth-child(3) {
                                        width: 100%;
                                        font-size: 19px;
                                    }
                                    &:nth-child(4) {
                                        width: 100%;
                                        font-size: 12px;
                                        span {
                                            &:first-child {
                                                float: left;
                                                font-weight: normal;
                                            }
                                            &:last-child {
                                                float: right;
                                                font-weight: 900;
                                            }
                                        }
                                    }
                                    &:nth-child(5) {
                                        .ant-progress-inner {
                                            background-color: #eee !important;
                                            .ant-progress-bg {
                                                height: 4px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .infotip {
                        color: #666;
                        font-size: 12px;
                        margin: 10px 0 0;
                        & li:first-child::before {
                            content: "*";
                            margin-right: 3px;
                        }
                        li {
                            margin-left: 8px;
                            &:first-child {
                                margin-left: 0px;
                            }
                        }
                    }
                    &:last-child {
                        color: #666666;
                        font-size: 12px;
                        .leeveAndDetail {
                            li {
                                width: 100%;
                                height: 40px;
                                &:nth-child(1) {
                                    height: 30px;
                                    div {
                                        width: 100%;
                                        display: flex;
                                        justify-content: space-between;
                                        height: 30px;
                                        span {
                                            &:last-child {
                                                color: #1b8dff;
                                            }
                                        }
                                    }
                                }
                                &:nth-child(2),
                                &:nth-child(3) {
                                    background: transparent
                                        linear-gradient(
                                            90deg,
                                            #e0cdac 0%,
                                            #e0cdac 100%
                                        )
                                        0% 0% no-repeat padding-box;
                                    border-radius: 8px;
                                    opacity: 1;
                                    margin-bottom: 20px;
                                    .L001,
                                    .L002,
                                    .L003,
                                    .L004 {
                                        display: inline-block;
                                        width: 64px;
                                        height: 40px;
                                        line-height: 40px;
                                        border-radius: 8px 0px 0px 8px;
                                        font-weight: bold;
                                        font-size: 20px;
                                        text-align: center;
                                        color: #7685bc;
                                        background: transparent
                                            linear-gradient(
                                                180deg,
                                                #e4effd 0%,
                                                #a5c6fd 100%
                                            )
                                            0% 0% no-repeat padding-box;
                                        float: left;
                                    }

                                    .L005,
                                    .L006,
                                    .L007 {
                                        display: inline-block;
                                        width: 64px;
                                        height: 40px;
                                        line-height: 40px;
                                        border-radius: 8px 0px 0px 8px;
                                        font-weight: bold;
                                        font-size: 20px;
                                        text-align: center;
                                        color: #7740d0;
                                        background: transparent
                                            linear-gradient(
                                                180deg,
                                                #f3e8ff 0%,
                                                #b692df 100%
                                            )
                                            0% 0% no-repeat padding-box;
                                        float: left;
                                    }
                                    .L008,
                                    .L009,
                                    .L010 {
                                        display: inline-block;
                                        width: 64px;
                                        height: 40px;
                                        line-height: 40px;
                                        border-radius: 8px 0px 0px 8px;
                                        font-weight: bold;
                                        font-size: 20px;
                                        text-align: center;
                                        color: #a67719;
                                        background: transparent
                                            linear-gradient(
                                                180deg,
                                                #ffffff 0%,
                                                #f7c965 100%
                                            )
                                            0% 0% no-repeat padding-box;
                                        float: left;
                                    }
                                    .rightd {
                                        width: calc(100% - 64px);
                                        padding: 0 10px;
                                        float: left;
                                        color: #222222;
                                        .rightd-text {
                                            width: 100%;
                                            height: 40px;
                                            line-height: 40px;
                                            p {
                                                width: 50%;
                                                margin-bottom: 0;
                                                float: left;

                                                &:last-child {
                                                    text-align: right;
                                                    span {
                                                        font-weight: bold;
                                                    }
                                                }
                                            }
                                        }
                                        &.haveProgress {
                                            height: 40px;
                                            display: flex;
                                            flex-direction: column;
                                            align-items: center;
                                            .rightd-text {
                                                width: 100%;
                                                height: 15px;
                                                margin-top: 5px;
                                                p {
                                                    height: 15px;
                                                    line-height: 15px;
                                                }
                                            }
                                            .progressRate {
                                                width: 100%;
                                                height: 20px;
                                                .ant-progress {
                                                    height: 20px;
                                                    div {
                                                        height: 20px;
                                                        .ant-progress-outer {
                                                            height: 4px;
                                                            .ant-progress-inner {
                                                                height: 4px;
                                                                background-color: #eee !important;
                                                                .ant-progress-bg {
                                                                    height: 4px !important;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &:last-child {
                                    width: 100%;
                                    line-height: 40px;
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
            .halftoneL {
                width: 210px;
                height: 63px;
                background: url(/cn/img/vip/halftoneL.png) center no-repeat;
                background-size: contain;
                position: absolute;
                left: 0;
                bottom: 0;
            }
            .halftoneR {
                width: 210px;
                height: 63px;
                background: url(/cn/img/vip/halftoneR.png) center no-repeat;
                background-size: contain;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
        .Preferential-application {
            width: 100%;
            .promo-list {
                .ant-row {
                    width: 100%;
                    .ant-col-11 {
                        .ant-form-item-label {
                            line-height: 30px;
                        }
                        .ant-btn {
                            width: 100%;
                        }
                        .ant-btn[disabled] {
                            background-color: #dbcaa7;
                            color: #eee;
                        }
                    }
                    .ant-col-12 {
                        .promo-child-title2 {
                            width: 100%;
                            text-align: center;
                            font-size: 18px;
                            font-weight: 600;
                        }
                        .drop-area .ant-select-lg {
                            width: 100%;
                        }

                        .offer-details {
                            .ant-col {
                                line-height: 50px;
                                img {
                                    margin-right: 15px;
                                }
                            }
                        }
                        .vipExclusiveText {
                            line-height: 70px;
                            color: #1b8dff;
                            .ant-col {
                                margin-right: 20px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        .Promotion-Rewards {
            width: 100%;
            .rewards-list {
                position: relative;
                .clearfix;
                .checkDetails {
                    color: #1b8dff;
                    position: absolute;
                    right: 50px;
                    top: 10px;
                    cursor: pointer;
                }
                ul {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: 0 0 15px #dcc294;
                    margin-bottom: 0;
                    .clearfix;
                    li {
                        width: 100%;
                        float: left;
                        text-align: center;
                        background-color: white;
                        border-bottom: 1px solid #eee;
                        &:first-child {
                            color: #a67719;
                            background-color: #fff3d9;
                            border-bottom: 1px solid #dcc294;
                            font-weight: 600;
                        }
                        &:last-child {
                            border-bottom: 0px;
                        }
                        div {
                            width: 8.5%;
                            height: 56px;
                            line-height: 56px;
                            float: left;
                            position: relative;
                            z-index: 10;
                            &:first-child {
                                width: 15%;
                                display: inline-block;
                            }
                            &.active {
                                span {
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    z-index: 12;
                                }
                            }
                        }
                        &.title-line {
                            div {
                                &.active {
                                    &::before {
                                        display: block;
                                        content: "";
                                        position: absolute;
                                        z-index: 11;
                                        top: -3px;
                                        left: 0;
                                        width: 100%;
                                        // height: 175px;
                                        border-radius: 16px 16px 0 0;
                                        box-shadow: 0px -2px 0px #00000029;
                                    }
                                    &.Sky-blue {
                                        &::before {
                                            background: transparent
                                                linear-gradient(
                                                    180deg,
                                                    #e4effd 0%,
                                                    #a5c6fd 100%
                                                )
                                                0% 0% no-repeat padding-box;
                                        }
                                    }
                                    &.purple {
                                        &::before {
                                            background: transparent
                                                linear-gradient(
                                                    180deg,
                                                    #e4effd 0%,
                                                    #bc94f3 100%
                                                )
                                                0% 0% no-repeat padding-box;
                                        }
                                    }
                                    &.apricot {
                                        &::before {
                                            background: transparent
                                                linear-gradient(
                                                    180deg,
                                                    #e4effd 0%,
                                                    #f4c46c 100%
                                                )
                                                0% 0% no-repeat padding-box;
                                        }
                                    }
                                }
                            }
                            &.promotionRewards {
                                div {
                                    &.active {
                                        &::before {
                                            height: 175px;
                                        }
                                    }
                                }
                            }
                            &.bonusRatio {
                                div {
                                    &.active.height1 {
                                        &::before {
                                            height: 121px;
                                        }
                                    }
                                    &.active.height2 {
                                        &::before {
                                            height: 177px;
                                        }
                                    }
                                    &.active.height3 {
                                        &::before {
                                            height: 233px;
                                        }
                                    }
                                    &.active.height4 {
                                        &::before {
                                            height: 289px;
                                        }
                                    }
                                    &.active.height5 {
                                        &::before {
                                            height: 345px;
                                        }
                                    }
                                    &.active.height6 {
                                        &::before {
                                            height: 401px;
                                        }
                                    }
                                    &.active.height7 {
                                        &::before {
                                            height: 457px;
                                        }
                                    }
                                    &.active.height8 {
                                        &::before {
                                            height: 513px;
                                        }
                                    }
                                    &.active.height9 {
                                        &::before {
                                            height: 569px;
                                        }
                                    }
                                    &.active.height10 {
                                        &::before {
                                            height: 625px;
                                        }
                                    }
                                }
                            }
                            &.rebate {
                                div {
                                    &.active.height1 {
                                        &::before {
                                            height: 121px;
                                        }
                                    }
                                    &.active.height2 {
                                        &::before {
                                            height: 177px;
                                        }
                                    }
                                    &.active.height3 {
                                        &::before {
                                            height: 233px;
                                        }
                                    }
                                    &.active.height4 {
                                        &::before {
                                            height: 289px;
                                        }
                                    }
                                    &.active.height5 {
                                        &::before {
                                            height: 345px;
                                        }
                                    }
                                    &.active.height6 {
                                        &::before {
                                            height: 401px;
                                        }
                                    }
                                    &.active.height7 {
                                        &::before {
                                            height: 457px;
                                        }
                                    }
                                    &.active.height8 {
                                        &::before {
                                            height: 513px;
                                        }
                                    }
                                    &.active.height9 {
                                        &::before {
                                            height: 569px;
                                        }
                                    }
                                    &.active.height10 {
                                        &::before {
                                            height: 625px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .Common-Problem {
            width: 100%;
            .problem-list {
                .ant-col {
                    &:nth-child(1),
                    &:nth-child(2) {
                        margin-bottom: 20px;
                    }
                    p {
                        width: 100%;
                        height: 56px;
                        line-height: 56px;
                        text-align: center;
                        background: #dcc294;
                        border-radius: 10px;
                        font-size: 18px;
                        font-weight: 600;
                    }
                    span {
                        width: 100%;
                        display: inline-block;
                        text-align: center;
                        padding: 0 20px;
                    }
                }
            }
        }
        .Regulations {
            width: 100%;
            .Regulations-list {
                font-weight: 600;
                .ant-row {
                    .ant-col-2 span {
                        width: 56px;
                        height: 56px;
                        line-height: 56px;
                        text-align: center;
                        font-size: 24px;
                        border-radius: 10px;
                        background-color: #dcc294;
                        display: inline-block;
                    }
                    .ant-col-22 {
                        span {
                            font-size: 18px;
                        }
                        p {
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }
}
.BonusRatioModal {
    background: #fff3d9;
    border-radius: 30px;
    box-shadow: 0 0 15px #dcc294;
    min-height: 500px;
    padding-bottom: 0 !important;
    .ant-modal-header {
        background-color: transparent;
        .public-title {
            width: 480px;
            height: 58px;
            background: url(/cn/img/vip/titlebg.png) center no-repeat;
            background-size: contain;
            color: #a67719;
            font-size: 24px;
            text-align: center;
            line-height: 58px;
            margin: 25px auto;
            font-weight: 600;
        }
    }
    .ant-modal-content {
        background-color: transparent;
        box-shadow: none;
        .GameEffectiveM {
            width: 92%;
            margin: auto;
            min-height: 200px;
            .ant-row-flex {
                font-size: 12px;
                color: #222222;
                .ant-col-11,
                .ant-col-13 {
                    height: 40px;
                    line-height: 40px;
                    background: #f5e9d0;
                    border-radius: 8px;
                    margin-bottom: 20px;
                    .clearfix;
                    .title {
                        width: 120px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        background: transparent
                            linear-gradient(180deg, #e4bd83 0%, #a67334 100%) 0%
                            0% no-repeat padding-box;
                        border-radius: 8px 0px 0px 8px;
                        opacity: 1;
                        font-size: 20px;
                        color: #fff;
                        font-weight: bold;
                        float: left;
                        overflow: hidden;
                    }
                    .content {
                        width: calc(100% - 112);
                        span {
                            .clearfix;
                            &:first-child {
                                float: left;
                                padding-left: 20px;
                            }
                            &:last-child {
                                float: right;
                                padding-right: 20px;
                                font-weight: bold;
                            }
                        }
                    }
                }
                .ant-col-24 {
                    text-align: right;
                    margin-bottom: 20px;
                }
                .ant-col-22 {
                    font-size: 18px;
                    color: #222222;
                    font-weight: bold;
                }
                .ant-col-7 {
                    text-align: left;
                    .ant-select-lg {
                        width: 100%;
                    }
                }
                &:first-child {
                    position: relative;
                    .next {
                        width: 40px;
                        height: 80px;
                        background: url(/cn/img/home/part/arrow-to-right.png)
                            center no-repeat;
                        background-size: contain;
                        position: absolute;
                        right: -40px;
                        top: calc(50% - 70px);
                        &:hover {
                            cursor: pointer;
                            background-color: hsla(0, 0%, 100%, 0.2);
                        }
                    }
                    .prev {
                        width: 40px;
                        height: 80px;
                        background: url(/cn/img/home/part/arrow-to-left.png)
                            center no-repeat;
                        background-size: contain;
                        position: absolute;
                        left: -40px;
                        top: calc(50% - 70px);
                        &:hover {
                            cursor: pointer;
                            background-color: hsla(0, 0%, 100%, 0.2);
                        }
                    }
                }
                &.isLastFour {
                    display: block;
                    .ant-col-11 {
                        float: none;
                        &.updateTime-11 {
                            text-align: right;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}
.vipNoticeModal {
    background: transparent linear-gradient(180deg, #fffaef 0%, #dcc294 100%) 0%
        0% no-repeat padding-box;
    padding-bottom: 0 !important;
    border-radius: 16px;
    .ant-modal-content {
        background-color: transparent;
        box-shadow: none;
        .ant-modal-body {
            padding: 20px 32px 20px;
            .ant-modal-confirm-content {
                margin-top: 12px;
            }
        }
        .ant-modal-confirm-btns {
            margin-top: 0;
        }
    }
}
