@datepick25-div-height:43px;
.datepick25 {
  .datepick25-r {
      float: right;
      margin-right: 18px;
  }

  .datepick25-r .ant-btn {
      font-size: 14px;
      background: #00A6FF;
      height: 36px;
      width: 80px;
      line-height: 36px;
      border-radius: 6px;
  }

  .datepick25-l {
        float:right;
      cursor: pointer;
      color: #666;
      font-size: 14px;
      margin: 0 16px 0 18px;
      width: 240px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #E3E3E3;
      border-radius: 6px;
      text-indent: 16px;
      background: url(/cn/img/1wallet/datepicker-cal.svg) #fff no-repeat 90% 50%;
  }

  .datepick25-pop {
        transform:translate(-4px, 41px);
        opacity: 0;
        pointer-events: none;
      width: 375px;
      height: 312px;
      border-radius: 6px;
      border: 1px solid #E3E3E3;
      background: #fff;

      .bd {
          height: 242px;
      }

      .bd2 {
        cursor: grab;
          margin: 0 16px;
          display: flex;
        gap:0;
        justify-content: flex-start;
        background: url(/cn/img/1wallet/datepick-pop-bg.png) #fff no-repeat 0 86px;
        height:215px;
        transform:translateY(-25px)
      }

      .bd-l,
      .bd-r {
        position: relative;
          text-align: center;
          font-size: 18px;
          width: 188px;
          overflow: hidden;
          height: 100%;

          > div {
              height: @datepick25-div-height;
              line-height: @datepick25-div-height;
          }
      }
    .bd-r {
        width:187px
    }
    .bd-l::before,
    .bd-r::before,
    .bd-l::after,
    .bd-r::after {
        content: '';
        display: block;
        pointer-events: none;
        background:#fff;
        width:100%;
        height:@datepick25-div-height;
        position: fixed;
        left:0;
    }
    .bd-l::before,
    .bd-r::before{
        top:0;
    }
    .bd-l::after,
    .bd-r::after{
        bottom:0;
    }

      .ft {
          text-align: right;
          background: #EFEFF4;
          height: 70px;
          line-height: 70px;

          .ant-btn {
              margin: 0 16px;
              width: 140px;
              height: 38px;
              font-size: 16px;
          }
      }
  }
}
