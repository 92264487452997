.submit_btn {
    background: #2994ff 0% 0% no-repeat padding-box;
    border-radius: 8px;
    width: 200px;
    height: 40px;
    color: white;
    border: 0;
    font-size: 15px;
    cursor: pointer;
}

.ant-collapse-borderless {
    background-color: #fafafa00;
}

.help-txt-box h1 {
    margin: 0;
    color: #222;
}

.help-txt-box center {
    color: #e91e63;
    font-weight: bold;
}

.help-txt-box {
    width: 680px;
    margin: 0 auto;
}

.top-banner {
    height: auto;
    box-shadow: 0px 4px 8px #00000014;
    border-radius: 20px;
    position: relative;
    min-height: 200px;
    overflow: hidden;
}

.top-banner-txt {
    position: absolute;
    top: 40%;
    margin: 0 auto;
    left: 0;
    right: 0;
    color: white;
}

h1 {
    color: white;
}

.game-box-content {
    padding: 29px 0 0 0;
    text-align: center;
    margin-bottom: 100px;
}

.down-banner {
    margin: 45px 15px;
    background-image: linear-gradient(
        14deg,
        #d9d9d9 0%,
        #ffffff 50%,
        #ffffff 100%
    );
    margin-top: 45px;
    border-radius: 20px;
    height: 332px;
}

.content-down-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 332px;
    background-position: center;
    position: relative;
    &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border: 113px solid;
        border-color: transparent transparent #ffffffc2;
        position: absolute;
        left: 320px;
        bottom: 0;
    }
    &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border: 113px solid;
        border-color: transparent transparent #ffffffc2;
        position: absolute;
        left: 0;
        top: -136px;
        right: 0;
        margin: 0 auto;
    }
}

.content-down-banner center {
    position: absolute;
    top: 15px;
    z-index: 9;
}

.content-down-banner h4 {
    color: #00a6ff;
    font-weight: bold;
}

.content-down-banner h2 {
    margin: 0;
    color: #222;
}

.content-down-banner .left {
    width: 300px;
    text-align: left;
    color: #222222;
    margin: 0 50px;
}

.content-down-banner .right {
    width: 300px;
    text-align: left;
    color: #222222;
    background: #ffffffbf 0% 0% no-repeat padding-box;
    height: 111px;
    margin: 0 50px;
}

.content-down-banner .right p {
    text-align: center;
    padding-top: 10px;
    margin: 0;
}

.content-down-banner .right .icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-down-banner .right .icon li {
    width: 35%;
    text-align: center;
    font-size: 12px;
}

.content-down-banner .right .icon li p {
    padding: 0;
}

.content-down-banner .right .icon .number {
    font-size: 25px;
    font-weight: bold;
    color: #00a6ff;
}

.content-down-banner .right .icon .number p {
    padding: 0;
    font-size: 12px;
    font-weight: 500;
    color: #222;
}

.content-down-banner .right .icon .number span {
    font-size: 14px;
}

.live-item {
    margin-bottom: 29px;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 16px;
    padding-right: 40px;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    text-align: left;
}

.ant-collapse-borderless {
    border: 0;
    background-color: transparent;
}

.ant-collapse-content-box {
    text-align: left;
}

.Gameslobby {
    padding: 30px 0px;
    min-height: 300px;
    position: relative;
    .Title {
        text-align: left;
        border-left: 4px solid #00a6ff;
        padding-left: 10px;
        margin: 15px 0px;
        font-size: 20px;
        color: #222;
    }
}

.GamesHome {
    .Top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #00a6ff;
        cursor: pointer;
    }
    #InstantGames {
        .item:first-child {
            height: 353px;
            width: 370px;
        }
        .item {
            width: 177px;
            height: 169px;
        }
    }
    #P2P {
        .item:first-child {
            height: 303px;
            width: 370px;
        }
        .item {
            width: 177px;
            height: 145px;
        }
    }
    #LiveCasino {
        .item:first-child {
            height: 303px;
            width: 370px;
        }
        .item {
            width: 177px;
            height: 145px;
        }
    }
    #Slot {
        .item:first-child {
            height: 303px;
            width: 370px;
        }
        .item {
            width: 177px;
            height: 145px;
        }
    }
    // .LiveCasino-RecommendGames,
    // .P2P-RecommendGames,
    // .Slot-RecommendGames {
    //     .item:first-child {
    //         height: 337px !important;
    //     }
    //     .item {
    //         height: 161px !important;
    //     }
    // }
    .RecommendGames {
        height: 290px;
        margin-bottom: 35px;

        .item:first-child {
            height: 290px;
            width: 349px;

            .openGame {
                .DefaultTag {
                    width: 60px !important;
                    height: 32px !important;
                    font-size: 14px;
                }

                &:hover {
                    &::before {
                        height: 73%;
                    }
                    &::after {
                        font-size: 18px;
                        width: 96px;
                        height: 44px;
                    }
                }

                .GameName {
                    height: 75px;
                    display: flex;
                    align-items: center;
                    font-size: 24px;
                    padding: 0 0 0 24px;
                }
            }
        }
        .item {
            width: 180px;
            height: 151px;
            //background: linear-gradient(rgba(0, 166, 255, 0.69) 0%, rgb(0, 166, 255) 100%) 0% 0% no-repeat padding-box
            //padding-box transparent;
            float: left;
            margin: 10px 10px 5px 0px;
            border-radius: 10px;
            overflow: hidden;
        }
        .item:nth-child(5),
        .item:last-child {
            margin-right: 0;
        }
    }

    .JackpotGamesContainer {
        position: relative;
        overflow: hidden;

        &::before {
            content: "";
            display: block;
            height: 100%;
            width: 200px;
            position: absolute;
            left: 920px;
            background: transparent
                linear-gradient(90deg, #ffffff00 0%, #f2f4f5 80%) 0% 0%
                no-repeat padding-box;
            z-index: 1;
        }

        .JackpotGames {
            display: flex;
            margin-bottom: 50px;
            position: relative;
            left: 0;
            transition: left 0.6s ease-out;

            .item {
                width: 850px;
                height: 251px;
                background-image: url(/cn/img/game/JackpotGames.webp);
                border-radius: 20px;
                margin-right: 15px;
                flex: 0 0 75%;
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .left {
                    flex: 0 0 29%;
                    height: 138px;
                    padding-left: 20px;

                    .openGame {
                       
                        .DefaultTag {
                            display: none;
                        }

                        img {
                            height: 100%;
                        }

                        .GameName {
                            display: none;
                        }

                        &:hover {
                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                background-color: rgba(0, 0, 0, 0.4);
                                width: 100%;
                                height: 180px;
                                z-index: 2;
                            }
                            &::after {
                                content: "开始";
                                font-size: 18px;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 96px;
                                height: 44px;
                                background: transparent
                                    linear-gradient(
                                        180deg,
                                        #79d1ff 0%,
                                        #00a6ff 100%
                                    )
                                    0% 0% no-repeat padding-box;
                                border-radius: 30px;
                                z-index: 2;
                            }
                        }
                    }
                }
                .right {
                    flex: 0 0 60%;
                    height: 138px;
                    .JackpotName {
                        position: relative;
                        display: flex;
                        width: 100%;
                        padding-left: 30px;
                        font-size: 24px;
                        align-items: center;
                        justify-content: flex-start; //css

                        .DefaultTag {
                            position: static !important;
                            margin-right: 15px;
                            width: 60px !important;
                            height: 38px !important;

                            span {
                                font-size: 18px;
                            }
                        }

                        & > span {
                            text-align: left;
                            font-weight: bold;
                        }
                    }
                    .box-item {
                        height: auto;
                        display: flex;
                        transform: scale(0.85);
                        margin-left: -10px;
                        margin-bottom: 39px;
                    }
                    .number-item {
                        width: 34px;
                        height: 51px;
                        list-style: none;
                        margin-right: 1px;
                        background-image: url("/cn/img/game/Numbg.webp");
                        background-position: center;
                        background-repeat: no-repeat;
                        border-radius: 4px;
                        border: 1px solid rgba(221, 221, 221, 1);
                        display: flex;
                        align-items: center;

                        & > span {
                            position: relative;
                            display: flex;
                            width: 100%;
                            height: 100%;
                            writing-mode: vertical-rl;
                            text-orientation: upright;
                            overflow: hidden;
                            align-items: center;
                            justify-content: center;
                            color: white;
                            font-weight: bold;
                            // & > i {
                            // 	font-style: normal;
                            // 	position: absolute;
                            // 	top: 0;
                            // 	left: 50%;
                            // 	transform: translate(-50%, 0);
                            // 	transition: transform 1s ease-in-out;
                            // 	letter-spacing: 10px;
                            // 	font-size: 16px;
                            // 	color: white;
                            // 	font-weight: bold;
                            // 	text-align: center;
                            // }
                        }
                    }
                    .number-item:last-child {
                        margin-right: 0;
                    }
                    .comma {
                        display: inline-block;
                        color: #00a6ff;
                        font-size: 30px;
                    }
                    .mark-item {
                        display: flex;
                        align-items: flex-end;
                        padding: 2px 0;
                    }
                }
            }
        }
    }
    .GamesPlatform {
        display: flex;
        flex-wrap: wrap;
        gap: 31px;
        margin-bottom: 50px;

        .item {
            width: 200px;
            height: auto;
            // background: linear-gradient(
            //         rgba(0, 166, 255, 0.69) 0%,
            //         rgb(0, 166, 255) 100%
            //     )
            //     0% 0% no-repeat padding-box padding-box transparent;
            border-radius: 10px;
            overflow: hidden;
            flex: 0 0 17.66%;
            position: relative;
            cursor: pointer;
            img {
                border-radius: 10px;
                width: 100%;
                height: 100%;
            }
            .name {
                position: absolute;
                bottom: 10px;
                width: 100%;
                color: white;
            }
        }
        .item.isHot::before {
            content: "";
            font-size: 18px;
            position: absolute;
            top: -3%;
            left: -7%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 43%;
            height: 100px;
            background: transparent;
            background-image: url(/cn/img/hot.png);
            z-index: 2;
            transform: rotate(-2deg);
            background-position: 8px 0px;
            background-repeat: no-repeat;
            background-size: 80%;
        }
        .item.isNew::before {
            content: "";
            font-size: 18px;
            position: absolute;
            top: -3%;
            left: -7%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 43%;
            height: 100px;
            background: transparent;
            background-image: url(/cn/img/new.png);
            z-index: 2;
            transform: rotate(-2deg);
            background-position: 8px 0px;
            background-repeat: no-repeat;
            background-size: 80%;
        }
    }
    .GamesType {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        .item {
            width: 200px;
            height: 76px;
            background: white;
            border-radius: 10px;
            flex: 0 0 17.66%;
            display: flex;
            align-items: center;
            padding-left: 20px;
            cursor: pointer;

            img {
                width: 46px;
                height: 46px;
                margin-right: 10px;
            }
        }
    }
}

.GamesPlatformSkeleton {
    display: flex;
    flex-wrap: wrap;
    gap: 31px;
    margin-bottom: 50px;

    .item {
        width: 197.79px;
        height: 197.79px;
        border-radius: 10px;
    }
}

.openGame {
    position: relative;
    height: 100%;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 10px;
    background-color: white;
    &:hover {
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
            width: 100%;
            height: 74%;
            z-index: 2;
        }
        &::after {
            content: "开始";
            font-size: 14px;
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 78px;
            height: 31px;
            background: transparent
                linear-gradient(180deg, #79d1ff 0%, #00a6ff 100%) 0% 0%
                no-repeat padding-box;
            border-radius: 30px;
            z-index: 2;
        }
    }

    .GameName {
        height: 35px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        background: white;
        text-align: left;
        position: absolute;
        width: 100%;
        bottom: 0;
        color: #222222;
        font: normal normal bold 12px/16px Microsoft YaHei UI;
    }
}

.jackpot-slide-arrow {
    width: 40px;
    height: 80px;
    position: absolute;
    top: 560px;
    outline: none;
    border-width: 0;
    border-radius: 4px;
    cursor: pointer;
    background-color: #00000040;
    transition: background-color 0.3s;
    z-index: 2;

    &:hover {
        background-color: #00000080;
    }

    &.slide-prev {
        left: -10px;
        background-image: url("/cn/img/home/part/arrow-to-left.png");
    }
    &.slide-next {
        right: -10px;
        background-image: url("/cn/img/home/part/arrow-to-right.png");
    }
}

// Home Banner
#games_banner_wrapper {
    #t_banner_wrapper {
        &.tlc-banner-list > div > div .tlc-banner-item {
            background-color: #EBEBEB;
        }
        &.tlc-banner-list {
            position: relative;
            height: 185px;
            line-height: 185px;
            top: 0;
            left: 0;
        }
        .tlc-banner-item {
            width: 1120px;
            height: 185px;
            line-height: inherit;
            text-align: center;
            color: #fff;
            border-radius: 10px;
            overflow: hidden;
            box-sizing: border-box;
            color: transparent;
            overflow: hidden;
            background-color: #ebebeb;
        }
        .tlc-banner-item.pointer {
            cursor: pointer;
        }
        .tlc-banner-item img {
            height: 100%;
            vertical-align: top;
            border-radius: 10px;
        }
        .tlc-carousel-controller {
            position: absolute;
            height: 0;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 5;
        }
        .slide-arrow {
            width: 30px;
            height: 60px;
            position: absolute;
            top: 59px;
            outline: none;
            border-width: 0;
            border-radius: 8px;
            cursor: pointer;
            background-color: #00000040;
            background-position: center;
            background-repeat: no-repeat;
            transition: background-color 0.3s;
        }
        .slide-arrow:hover {
            background-color: #00000080;
        }
        .slide-prev {
            left: 20px;
            background-image: url("/cn/img/home/part/arrow-to-left-lobby.png");
        }
        .slide-next {
            right: 100px;
            background-image: url("/cn/img/home/part/arrow-to-right-lobby.png");
        }
        .slide-list {
            width: 100%;
            height: 4px;
            position: absolute;
            text-align: center;
            top: 170px;
            right: 40px;
            white-space: nowrap;
            overflow: hidden;
        }
        .slide-item {
            display: inline-block;
            margin: 0 5px;
            width: 50px;
            height: inherit;
            background-color: #fff;
            opacity: 0.3;
            transition: opacity 0.3s;
            text-indent: -999999px;
        }
        .slide-item.slide-item-active {
            opacity: 1;
        }
    }
}