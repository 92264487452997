@mobile-breakpoint: 768px;
@tablet-breakpoint: 1200px;
@colorType1: #00A6FF;
@colorType2: #E6F6FF;
@backgroundColor: #fff;



@media (max-width: @mobile-breakpoint) {
    .searchContainer {
        .textInputBox {
            display: none;
        }
    }
}

// @media (min-width: @mobile-breakpoint) and (max-width: @tablet-breakpoint) {
//     .searchWebBgBox {
//         display: none;
//     }
// }

@media (min-width: @mobile-breakpoint) {
    div.CustomerService {
        background-color: #F6F6F6;

        // web header
        .maintain-header-wrap {
            width: 100%;
            height: 60px;
            background-color: @colorType1;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 999;


            .common-distance {
                width: 1200px;
                padding: 0 40px;
                margin: 0 auto;
                position: relative;

                .logo-wrap {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    >div {
                        display: flex;
                        align-items: center;
                    }

                    .line {
                        color: #fff;
                        font-weight: 600;
                        margin: 0 12px;
                        background-color: transparent;
                        width: 1px !important;
                    }

                    span.pageTitle {
                        color: #fff;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    #CustomerService {
        background-color: #F6F6F6;
        // width: 1200px;
        padding: 80px 0 0;
        margin: 0 auto;

        .liveChatContainer {
            left: initial;
            right: 40px;
            bottom: 40px; // https://arcadie.atlassian.net/browse/CUSP-49
            border-radius: 999px;
            width: 70px;
            height: 70px;

            .contact {
                height: 40px;
                width: 40px;
            }
        }


        .headerBox {
            display: none;
        }


        .detailNavBox {
            display: none;
        }

        .searchContainer {
            .detailNavBox {
                display: flex;

                .aiArrowBox {
                    background-color: #e1e1e1;
                    width: 18px;
                    height: 18px;
                    border-radius: 9999px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 12px;

                    .arrowIcon {
                        transform: rotate(45deg);
                        margin-right: 3px;
                    }
                }

                .detailNavText1 {
                    text-decoration: none;

                }
            }
        }




        .questionContentBox {
            .contentTitle {
                display: none;
            }

            margin-bottom: 0;
        }


        .helpContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: @colorType2;
            border: 2px solid @colorType1;
            margin-bottom: 0px;

            .helpTipText1 {
                text-align: left;
            }

            .helpTipText2 {
                text-align: left;
            }

            .helpTipBtn {
                width: auto;
                padding: 0 10px;
            }
        }


        .questionTopContainer {
            padding-left: 0;
            padding-right: 0;

            .searchBgBox {
                .searchInput {
                    display: none;
                }
            }

            .hotListBox {
                height: 60px;

                .hotListTitle {
                    margin-bottom: 0;
                    margin-right: 10px;
                }

                display: flex;
                align-items: center;
                justify-content: center;

                .hotListWrap {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.hotListWrapspecical {
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        width: 75%;
                    }

                    .hotList {
                        padding-top: 0;
                        padding-bottom: 0;
                        margin: 0 8px;
                        cursor: pointer;
                        text-align: center;

                        .hotListText {
                            text-align: center;
                        }
                    }
                }
            }
        }

        .question-wrap {
            display: flex;
            justify-content: space-between;

            .questionBottomContainer {
                width: 200px;
                margin-right: 20px;
                margin-bottom: 0;

                .knowledgeCategoriesBox {
                    padding: 15px 0;
                    margin-bottom: 0;

                    .knowledgeCategoriesList {
                        border-left: 6px solid transparent;
                        transition: all 0.3s;
                        border-bottom-width: 0;
                        cursor: pointer;

                        &.knowledgeCategoriesListActive {
                            border-left-color: @colorType1;
                            background-color: #F0F0F2;
                        }

                        .arrowIcon {
                            display: none;
                        }


                    }
                }
            }




            .questionDetailContainer {
                .knowledgeCategoriesBox {
                    margin-bottom: 0px;
                    background-color: @backgroundColor;
                    border-radius: 8px;
                    padding: 10px 30px;
                    width: 900px;
                    padding-bottom: 30px;
                }

                .knowledgeCategoriesListBox {
                    border-bottom: 1px solid #e3e3e3;
                    // padding: 15px 0;

                    .questionContentBox {
                        .contentWrap {
                            margin-bottom: 0;
                            padding-bottom: 15px;
                        }
                    }
                }

                .knowledgeCategoriesList {
                    border-bottom-width: 0px;
                    padding: 15px 0 !important;
                    margin-bottom: 0;

                    .knowledgeCategoriesText {
                        color: #222;
                        font-weight: 500;
                        font-size: 16px;
                    }

                    .arrowIcon {
                        margin-top: -2px;
                    }
                }

                .helpContainer {
                    display: none;
                }
            }
        }


        .tabBox {
            background-color: transparent;
            margin-bottom: 40px;
            margin-top: 20px;
            border-bottom: 2px solid #EFEFF4;
            justify-content: flex-start;


            .headerBoxList {
                width: auto;
                padding: 0 15px;
                border-bottom: 6px solid transparent;
                cursor: pointer;
                color: #BCBEC3;
                font-size: 18px; // https://arcadie.atlassian.net/browse/CUSP-55

                &.headerBoxListActive {
                    color: @colorType1;
                    border-bottom-color: @colorType1;
                }
            }
        }



        .tabContainers {
            position: relative;

            .searchShow {
                opacity: 1;
                width: auto;
                height: auto;
                z-index: initial;

                .serachContentWrap {
                    margin-bottom: 0;
                    padding: 30px 20px;

                    .aiContentHeader {
                        margin-bottom: 20px;
                    }
                }

                .noMessageBox {
                    padding: 200px 0;

                    .searchLength {
                        text-align: center !important;
                    }
                }
            }

            .searchHide {
                opacity: 0;
                width: 0;
                height: 0;
                z-index: -99999;
            }

            .questionShow {
                opacity: 1;
                width: auto;
                height: auto;
                z-index: initial;
                // position: absolute;
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            .questionhHide {

                opacity: 0;
                width: 0;
                height: 0;
                z-index: -99999;
            }


            .searchContainer {
                width: 100%;
                padding-top: 0px;
                height: auto;



                .serachInputWrap {
                    position: absolute;
                    top: -225px;
                    // top: -310px;
                    left: 26%;
                    right: 26%;
                    // transform: translateY(-190px);
                    display: flex;
                    padding: 0;
                    justify-content: center;
                    transition: all 0s;

                    .textInput {
                        padding-left: 20px;
                    }

                    .searchInputIcon {
                        right: 10px;
                        left: initial;
                    }





                    .searchResultTitleListBox {
                        position: absolute;
                        left: 0;
                        right: 0;
                        border-radius: 8px;
                        margin-top: 2px;
                        top: 44px;
                        box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
                        margin-bottom: 0;
                    }
                }

                .searchShow {
                    .searchLength {
                        text-align: left;
                        padding: 0;
                    }
                }
            }
        }


        div.paginationBox {
            justify-content: flex-start;
        }

        .searchContainer {
            .searchResultBox {
                padding: 30px;
            }
        }




        .noDataBox {
            padding: 200px 0;

            .mobileFeedBox {
                display: none;
            }
        }


        .questionDetailContainer {
            .noDataBox {
                padding: 200px 0;
                background-color: #fff;
                border-radius: 8px;
            }
        }


        .recordContainer {
            .recordListBoxMobile {
                display: none;
            }

            .calendarWrap {
                display: none;
            }

            .recordListBoxWeb {
                background-color: @backgroundColor;
                border-radius: 6px;
                margin-bottom: 8px;
                border: 1px solid #EAEAEA;
                margin: 10px 0;

                .recordTextTime {
                    color: #bcbec3 !important;
                }

                .recordTextCom {
                    color: #222 !important;
                }

                .recordList {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 15px;
                    border-bottom: 1px solid #EAEAEA
                }

                .recordList1 {
                    display: flex;

                    .recordList2 {
                        justify-content: space-between;
                        width: 22%;
                        padding: 8px 15px;



                        .recordList3 {
                            display: flex;
                        }
                    }

                    .recordList4 {
                        padding: 12px 15px;
                        padding-right: 60px;
                        display: flex;
                        justify-content: center;
                        /* align-items: center; */
                        width: 64%;
                        border-right: 1px solid #EAEAEA;
                        border-left: 1px solid #EAEAEA;
                        position: relative;
                        flex-direction: column;

                        .rightArrow {
                            position: absolute;
                            right: 20px;
                        }


                        .tipBox {
                            .tipBoxText1 {
                                margin-bottom: 0;
                            }
                        }

                        .recordTextCom {
                            margin-bottom: 8px;
                        }
                    }



                    .csBtn {
                        width: 14%;
                    }
                }

                .recordText2 {
                    color: #BCBEC3;
                    font-size: 10px;
                    font-weight: 400;
                }

                .recordText3 {
                    color: #666;
                    font-size: 12px;
                    font-weight: 400;
                }



                .eventNameBox {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }



            }

        }
    }


    .feedbackModalContainer {
        .submission {
            display: none;
        }
    }

}



.CustomerService {
    background-color: #EFEFF4;
    height: 100%;
    overflow: hidden;

    .hide {
        pointer-events: none;
        visibility: hidden;
    }

    #CustomerService {
        &.Mobile {
            display: flex;
            flex-direction: column;
            height: 100vh;
            /* 设置容器占满视口高度 */

            .scroll {
                overflow-y: scroll;
                flex: 1;
                /* 自动填满剩余空间 */
                margin: 10px 10px 0;

                // width: 100%;
                &.QuestionDetail {
                    margin-top: 0;
                }
            }
        }
    }


    .arrowIcon {
        margin-left: 8px;
        width: 6px;
        height: 6px;
        border-width: 2px;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: #666;
        border-left-color: #666;
        transform: rotate(-135deg);
        border-style: solid;
        transition: all 0.2s;
        cursor: pointer;
    }



    .detailNavBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;

        .detailNav {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 4px;
            cursor: pointer;


            .detailNavText1 {
                color: #666;
                font-size: 12px;
                font-weight: 400;
                text-decoration: underline;
            }

            .detailNavText2 {
                color: #222;
                font-size: 12px;
                font-weight: 400;
            }

            .detailNavText3 {
                //width: 75%;
            }

            .rightArrow1 {
                margin-left: 5px;
                margin-right: 10px;
                border-bottom-color: #000;
                border-left-color: #000;
                border-width: 1px;
                transform: rotate(-135deg);
            }
        }




    }

    .questionContentBox {
        height: 100%;
        transition: all 0.35s;
        line-height: normal;

        .contentTitle {
            font-size: 16px;
            font-weight: 600;
            color: #222;
            margin-top: 20px;
            margin-bottom: 15px;
            transition: all 0.35s;
        }

        .contentWrap {
            color: #222;
            font-size: 14px;
            font-weight: 400;
            transition: all 1s ease-in-out;
            word-break: break-word;
            white-space: normal;
            /* 默认情况下允许自动换行 */
            overflow-wrap: break-word;
            /* 处理长单词换行 */
            margin-bottom: 150px;

            img {
                width: 100%;
            }
        }
    }


    .paginationBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 80px;
        flex-wrap: wrap;

        .paginationBtn {
            width: 24px;
            height: 24px;
            border-radius: 4px;
            border-width: 1px;
            border-color: #E3E3E3;
            background-color: @backgroundColor;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 4px;
            margin-bottom: 4px;
            border-style: solid;
            cursor: pointer;
        }

        .paginationBtnText {
            color: #222;
            font-weight: 400;
            font-size: 12px;
        }

        .rightArrow2 {
            border-bottom-color: #222;
            border-left-color: #222;
            border-width: 1px;
            transform: rotate(45deg);
            margin-left: 4px;
        }

        .rightArrow3 {
            border-bottom-color: #222;
            border-left-color: #222;
            border-width: 1px;
            transform: rotate(-135deg);
            margin-left: -4px;
        }
    }


    .noDataBox {
        display: flex;
        flex-direction: column;
        /* 垂直布局 */
        justify-content: center;
        /* 水平居中 */
        align-items: center;
        padding: 100px 0px;



        .noDataImg {
            width: 80px;
            height: 80px;
            margin-bottom: 10px;
        }

        .noDataText {
            color: #BCBEC3;
            font-size: 16px;
            font-weight: 600;
        }

        .noDataText1 {
            color: #000;
            font-size: 14px;
            font-weight: 400;
            margin-top: 20px;
            margin-bottom: 10px;
            text-align: center;
            padding: 0 50px;
        }

        .mobileFeedBox {
            display: flex;
            flex-direction: column;
            /* 垂直布局 */
            justify-content: center;
            /* 水平居中 */
            align-items: center;
        }

        .noDataBtnText {
            color: #fff;
            font-size: 16px;
            font-weight: 400;
        }

        .noDataBtn {
            background-color: @colorType1;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            padding: 0 80px;
            cursor: pointer;
        }

    }



    // header
    .headerContainer {
        // background-color: @colorType1;

        .headerBox1 {
            height: 30px;
        }

        .searchHeaderBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            transition: 0.25s all ease-in-out;

            .arrowIcon {
                transform: rotate(45deg);
                margin-right: 10px;
                width: 14px;
                height: 14px;
            }
        }

        .headerBox {
            display: flex;
            flex-direction: row;
            background-color: @colorType1;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            padding-left: 20px;
            padding-right: 20px;




            .headerTitle {
                color: #FFFFFF;
                font-size: 16px;
                font-weight: 500;
            }

            .leftArrow {
                width: 14px;
                height: 14px;
                border-bottom-color: #fff;
                border-left-color: #fff;
                transform: rotate(45deg);
                margin-left: 0px;
            }

            .leftArrow1 {
                opacity: 0;
            }
        }
    }



    // tab
    .tabBox {
        display: flex;
        flex-direction: row;
        background-color: @colorType1;
        align-items: center;
        justify-content: space-between;

        .headerBoxList {
            height: 40px;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 3px solid @colorType1;
            font-size: 14px;
            font-weight: 400;
            color: @colorType2;
            transition: all 0.2s;

            &.headerBoxListActive {
                border-bottom-color: #fff;
                color: #fff;
            }
        }

        .headerText {
            font-size: 14px;
            font-weight: 400;
        }
    }


    // question top
    .questionTopContainer {
        .searchBox {
            border-radius: 10px;
            overflow: hidden;
            background-color: @backgroundColor;
            margin-bottom: 10px;



            .searchBg {
                width: 100%;
            }

            .searchBgBox {
                position: relative;

                .searchInput {
                    margin-left: 15px;
                    margin-right: 15px;
                    border-style: solid;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 15px;
                }
            }
        }


        .hotListBox {
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 16px;
            padding-bottom: 16px;


            .hotListTitle {
                color: @colorType1;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 5px;
            }

            .hotList {
                padding-top: 6px;
                padding-bottom: 6px;
            }

            .hotListText {
                color: #000;
                font-size: 14px;
                font-weight: 400;
                text-decoration: underline;
            }

        }
    }


    // 新闻公共列表样式
    .knowledgeCategoriesBox {
        border-radius: 8px;
        background-color: @backgroundColor;
        margin-bottom: 160px;
        //overflow: hidden;


        .knowledgeCategoriesList {
            padding: 15px 12px;
            border-bottom-width: 1px;
            border-bottom-color: #EAEAEA;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom-style: solid;
            cursor: pointer;

            .knowledgeCategoriesText {
                color: #666666;
                font-size: 16px;
                font-weight: 400;
                width: 90%;
                flex-wrap: wrap;
            }

            .rightArrow {
                width: 22px;
                height: 22px;
            }
        }
    }



    //questiondetail
    .questionDetailContainer {
        flex: 1;
        width: 100%;

        .knowledgeCategoriesBox {
            background-color: transparent;
            overflow: hidden;
            border-radius: 8px;

            .knowledgeCategoriesList {
                border-radius: 8px;
                border-radius: 0;
                background-color: @backgroundColor;
            }
        }
    }



    //search
    .searchContainer {
        display: flex;
        flex-direction: column;
        height: 100vh;

        .detailNavBox {
            .detailNav {
                .detailNavText1 {
                    text-decoration: none;
                }
            }
        }

        .serachInputWrap {
            margin-top: 10px;
            padding: 0 10px;
            margin-bottom: 10px;
        }

        .scroll {
            margin-top: 0 !important;
        }

        .serachContentWrap {
            margin-bottom: 170px;
            line-height: normal;
            background-color: #fff;
            padding: 15px;
            border-radius: 8px;
            margin-top: 15px;

            .aiContentHeader {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .aiContentHeaderText {
                    color: #222;
                    font-size: 16px;
                    font-weight: #222;
                }

                .aiContentHeaderImg {
                    width: 24px;
                    height: 24px;
                    margin-right: 5px;
                }
            }

            .searchResultAI {
                color: #222;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .searchLength {
            color: #222;
            font-size: 18px;
            font-weight: 600;
            padding: 0 10px;
            // margin-top: 20px;
            width: 100%;
            word-break: break-word;
            margin-top: 20px;
        }

        .searchResultBox {
            background-color: #FFF;
            border-radius: 8px;
            padding: 15px;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .searchResultList {
            border-bottom-width: 1px;
            border-bottom-color: #EFEFF4;
            margin-bottom: 15px;
            border-bottom-style: solid;
            cursor: pointer;
        }

        .searchResultListTitle {
            color: #222;
            font-size: 14px;
            font-weight: 600;
        }

        .searchResultListText {
            color: #666;
            font-size: 12px;
            font-weight: 400;
            margin-top: 10px;
            margin-bottom: 10px;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            /* 限制显示三行 */
            overflow: hidden;
            text-overflow: ellipsis;
            line-clamp: 3;
            /* 对非Webkit内核的支持 */
        }

        .noMessageBox {
            padding-top: 60%;
            display: flex;
            align-items: center;
            justify-content: center;

            .searchLength {
                text-align: center;
                padding: 0 25px;
                line-height: 26px;
            }
        }


        .searchResultTitleListBox {
            background-color: @backgroundColor;
            //margin-top: 10px;
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 4px;
            margin-bottom: 180px;
        }

        .searchResultTitleList {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom-width: 1px;
            border-bottom-color: #EFEFF4;
            border-bottom-style: solid;
            cursor: pointer;
        }

        .searchResultTitleListText {
            color: #222;
            font-size: 14px;
            font-weight: 600;
            padding: 10px 0;
        }
    }

    // 获得乐天使协助
    .helpContainer {
        border-radius: 8px;
        overflow: hidden;
        background-color: @backgroundColor;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 10px;


        .helpTipText1 {
            color: @colorType1;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
        }

        .helpTipText2 {
            color: #666;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            padding-top: 8px;
            padding-bottom: 8px;
        }

        .helpTipBtn {
            background-color: @colorType1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            width: 100%;
            height: 44px;
            cursor: pointer;
        }

        .helpTipBtnText {
            color: #fff;
            font-size: 16px;
            font-weight: 400;
        }
    }


    .liveChatContainer {
        position: fixed;
        right: 0;
        bottom: 40px;
        cursor: pointer;
        z-index: 99;
        background-color: @colorType1;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        .contact {
            width: 18px;
            height: 18px;
        }
    }


    .textInputBox {
        background-color: @backgroundColor;
        border-width: 1px;
        border-color: #E3E3E3;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 44px;
        border-style: solid;
        position: relative;
        // margin-bottom: 10px;
        display: flex;
        flex: 1;

        .textInput {
            padding-left: 42px;
            height: 100%;
            width: 100%;
            color: #222222;
            border: none;
            outline: none;
            box-sizing: border-box;
            font-size: 14px;
            padding-right: 20px;
            background-color: @backgroundColor;
        }

        .searchInputIcon {
            width: 24px;
            height: 24px;
            position: absolute;
            left: 10px;
            cursor: pointer;
        }
    }





    .recordContainer {
        flex: 1;

        .timeSelect {
            display: flex;
            /* 等效于 flexDirection: 'row' */
            flex-direction: row;
            align-items: center;
            padding-top: 5px;
            /* 等效于 paddingVertical: 5 */
            padding-bottom: 5px;
            padding-left: 12px;
            /* 等效于 paddingHorizontal: 12 */
            padding-right: 12px;
            background-color: @backgroundColor;
            border-radius: 9999px;

            span {
                color: #666;
                font-size: 12px;
                font-weight: 400;
            }

            .ToIcon {
                margin: 0 6px;
                width: 16px;
                height: 16px;
            }

            .TimeIcon {
                margin-left: 6px;
                width: 16px;
                height: 16px;
            }
        }

        .calendarBox {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
        }

        .calendar {
            width: 28px;
            height: 28px;
        }

        .recordListBox {
            line-height: normal;

            .recordTextStatus {
                font-size: 12px;
                font-weight: 400;
                text-align: right;
            }

            .recordTextTime {
                color: #999;
                font-size: 10px;
                font-weight: 400;
            }

            .recordTextCom {
                color: #666666;
                font-size: 12px;
                font-weight: 400;
                min-width: 85px;
                word-break: break-word;

                &.recordTextComM3 {
                    min-width: 115px;
                }
            }

            .csBtn {
                display: flex;
                align-items: center;
                justify-content: center;

                .csBtnText {
                    color: @colorType1;
                    font-weight: 400;
                    font-size: 12px;
                    border: 1px solid @colorType1;
                    border-radius: 8px;
                    padding: 0 6px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

            .rightArrow {
                width: 24px;
                height: 24px;
                transition: all 0.2s;
                cursor: pointer;
            }

            .hiddenText {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
            }


            .tipBox {
                background-color: @colorType2;
                border-radius: 8px;
                padding: 6px;
                margin-bottom: 10px;

                .tipBoxText1 {
                    color: @colorType1;
                    font-size: 12px;
                    font-weight: 400;
                    margin-bottom: 6px;
                }

                .tipBoxText2 {
                    color: #222222;
                    font-size: 12px;
                    font-weight: 400;
                    word-break: break-word;
                    transition: all 0.2s;
                }
            }


            .recordSignedImgbox {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;

                .recordImg {
                    width: 100px;
                    height: 100px;
                    cursor: pointer;
                    border-radius: 8px;
                    overflow: hidden;
                }
            }


        }

        .recordListBoxMobile {
            background-color: @backgroundColor;
            border-radius: 6px;
            margin-bottom: 8px;
            padding: 12px 8px;



            .recordList {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
            }

            .recordList1 {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .recordTexCom {
                width: 92px;
            }

            .recordText2 {
                color: #222222;
                font-size: 11px;
                font-weight: 400;
                max-width: 120px;
            }

            .recordTextStatus {
                max-width: 120px;
            }

            .recordText3 {
                color: #999999;
                font-size: 12px;
                font-weight: 400;
                margin-top: 10px;
            }

            .eventNameBox {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            .tipBoxText2 {
                font-size: 12px;
                word-break: break-word;
            }

            .recordBottom {
                border-top: 1px solid #EAEAEA;
                padding-top: 10px;
            }
        }

    }
}









.CustomerServiceDropDownWrap {
    .drawer-custom-content-wrapper {
        display: flex;
        align-items: flex-end;
    }

    .CustomerServiceDropDown {
        border-top-right-radius: 16px;
        background-color: #efeff4;
        border-top-left-radius: 16px;
        overflow: hidden;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 15px;
        width: 100%;
        /* Assuming you want the width to be 100%, adjust if necessary */
        padding-bottom: 10px;

        .modalHeader {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            >div {
                flex: 1;
            }

            >span {
                flex: 1;
            }

            .modalClose {
                width: 24px;
                height: 24px;
            }

            .modalHeaderTitle {
                color: #222222;
                font-size: 16px;
                font-weight: 700;
                text-align: center;
            }

            .modalHeaderRightText {
                color: @colorType1;
                font-weight: 600;
                font-size: 16px;
                text-align: right;
            }

        }


        //ffeedback
        .modalList {
            height: 40px;
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: #ffffff;
            margin-bottom: 6px;
            padding: 0 10px;
        }

        .modalListText {
            color: #222;
            font-size: 14px;
            font-weight: 400;
        }

        .modalListCircle {
            width: 20px;
            height: 20px;
            border-radius: 10000px;
            border-width: 2px;
            border-style: solid;
            border-color: #BCBEC3;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .modalListInnerCircle {
            background-color: @colorType1;
            width: 10px;
            height: 10px;
            border-radius: 1000px;
        }








        // record
        .selectBox {
            margin-bottom: 15px;
        }

        .selectBoxTitle {
            color: #222;
            font-size: 16px;
            font-weight: 600;
        }

        .selectListBox {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            background-color: @backgroundColor;
            padding: 12px;
            padding-bottom: 6px;
            border-radius: 8px;
            margin-top: 8px;
        }

        .selectBtn {
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            margin-right: 5px;
            border: 1px solid #666;
            width: auto;
            margin-bottom: 6px;
        }

        .selectBtnText {
            font-size: 12px;
            font-weight: 400;
            color: @colorType1;
        }
    }
}


.CustomerServiceModal.CustomerServiceModalNo .confirm-btn-wrap {
    display: none;
}

.CustomerServiceModal {

    // top: 20% !important;
    &.modal-dialog {
        left: 5% !important;
        right: 5% !important;
        margin: initial !important;
        // https://arcadie.atlassian.net/browse/CUSP-6
    }

    .modaluploadImg {
        width: 80%;
    }

    .confirm-btn-wrap {
        .button_sportBtn__mSDS3 {
            margin-bottom: 0 !important;
        }
    }

    .uploadImgErrImg {
        width: 58px;
        height: 58px;
    }


    .uploadImgErrText {
        text-align: center;
        color: #222;
        font-size: 14px;
        font-weight: 400;
        margin-top: 25px;
        padding-left: 25px;
        padding-right: 25px;
        margin-bottom: 5px;
    }

    .modaluploadImgName {
        color: #222222;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        margin-top: 15px;
        width: 80%;
        word-wrap: break-word;
    }

    .CustomerServiceModalContainer {
        >div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        text-align: center;
    }

    &.CustomerServiceModalNoFooter {
        .ant-modal-confirm-btns {
            display: none !important; // web
        }

        .confirm-btn-wrap {
            display: none !important; // mobile
        }
    }

    &.CustomerServiceModalNoheader {
        .ant-modal-confirm-title {
            // web
            display: none;
        }
    }

    .ant-modal-confirm-btns {
        margin: 0 30px 30px !important; // 样式兼容 M3与M12不一样

        span {
            color: #fff !important; // 样式兼容 M3与M12不一样
        }
    }
}



.transfer-drawer.photo-drawer {
    .transfer-drawer .drawer-custom-content {
        background-color: transparent !important;
        display: flex;
        align-items: flex-end;
    }


    .viewPhotoContainer {
        margin: 0 10px 20px 10px;
        width: 100%;

        .photoListBox {
            background-color: rgba(249, 249, 249, 0.78);
            overflow: hidden;
            border-radius: 10px;
        }

        .photoList {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            padding: 0 15px;
            overflow: hidden;
            position: relative;
        }

        .photoList0 {
            border-bottom-width: 1px;
            border-bottom-color: #C6C6C8;
        }

        .photoListText {
            color: #007AFF;
            font-size: 20px;
            font-weight: 400;
        }

        .photoIcon {
            width: 25px;
            height: 25px;
        }

        .Modal_file_btn {
            width: 100%;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            opacity: 0;
            // filter: alpha(opacity=0);
            height: 100%;
        }

        .photoCancleBtnText {
            font-size: 20px;
            font-weight: 700;
            color: #007AFF;
        }

        .photoCancleBtn {
            background-color: rgba(255, 255, 255, 0.92);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 46px;
            margin-top: 15px;
            overflow: hidden;
            border-radius: 10px;
        }

    }
}


.CustomerServiceCalendar {
    .modalSelectTimeWrap {
        .modalTimeTip {
            color: #666;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            padding: 0 15px;
        }

        .modalSelectTimeBox {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 12px 0;

            .modalSelectTime {
                color: #222;
                font-size: 16px;
                font-weight: 600;
                margin: 0 8px;
            }
        }
    }

    .confrimTimeBtn {
        background-color: @colorType1;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        margin: 15px 0 0;

        .confrimTimeBtnText {
            color: #fff;
            font-size: 16px;
            font-weight: 400;
        }
    }

    .react-calendar__year-view {
        .react-calendar__year-view__months {
            .react-calendar__tile {
                padding: 10px !important;
                display: flex;
                align-items: center;
                justify-content: center;

                abbr[aria-label] {
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative !important;
                }
            }

            .renderDate {
                display: none;
            }

            .react-calendar__tile--hasActive {
                border-radius: 999px !important;
            }
        }
    }

    .react-calendar {
        .react-calendar__navigation {
            margin-bottom: 0px !important;
            background-color: #fff !important;

            .react-calendar__navigation__label {
                pointer-events: none;
            }

            button[disabled] {
                opacity: 0;
            }
        }
    }


    .rmc-picker-popup-header {
        margin-bottom: 10px;
        padding: 15px 10px 0;
    }

    .rmc-picker-popup-header-left {
        padding-left: 0px;
        padding-right: 0px;
    }

    .rmc-picker-popup-header-right {
        padding-left: 0px;
        padding-right: 0px;
    }

    .react-daterange-picker__wrapper {
        visibility: hidden;
        pointer-events: none;
    }


    .react-calendar__month-view__days__day--neighboringMonth {
        color: #FFF !important;
    }


    .react-calendar__month-view__days__day--neighboringMonth.react-calendar__tile--active {
        color: #e6f6ff !important;
    }



    .react-daterange-picker__calendar {
        position: relative;
    }


    .react-daterange-picker__calendar {
        margin-top: -50px;
    }

    .drawer-custom-content-wrapper {

        .drawer-custom-content {
            .sport-calendar-wrap {
                padding: 0 !important;
                margin: 0 -10px !important;

                .react-daterange-picker {
                    display: flex;
                    flex-direction: column;

                    .react-daterange-picker__calendar {
                        position: static !important;
                        height: fit-content !important;

                        .react-calendar__month-view__weekdays {
                            display: flex;
                            /* flex-direction: column; */
                            justify-content: center;
                            align-items: center;

                            .react-calendar__month-view__weekdays__weekday {
                                flex: 0 0 48px !important;

                                abbr {
                                    text-decoration: none !important;
                                }
                            }
                        }

                        .react-calendar__month-view__days {
                            display: flex;
                            /* flex-direction: column; */
                            justify-content: center;
                            align-items: center;
                            padding-bottom: 6px;

                            abbr[aria-label] {
                                display: none;
                            }

                            .react-calendar__tile {
                                color: #222;

                                &:disabled {
                                    background-color: white;
                                    color: #BCBEC3;
                                }


                                >abbr {
                                    line-height: 48px !important;
                                }

                                flex: 0 0 48px !important;
                                font-size: 16px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 48px;
                                line-height: 48px !important;
                            }

                            // .react-calendar__tile.react-calendar__tile--rangeStart {
                            //     line-height: 48px !important;

                            //     >abbr {
                            //         background-color: @colorType1;
                            //         border: @colorType1 1px solid;
                            //         color: #FFF;
                            //         line-height: 48px !important;
                            //     }
                            // }

                            .react-calendar__tile--active {
                                color: #666;
                            }
                        }
                    }
                }
            }
        }
    }
}






//web
.selectBtnWrap {
    display: flex;
    justify-content: flex-end;

    .customerServiceSelect {
        margin-left: 12px;

        &.customerServiceSelectM2 {
            .ant-select-selection {
                min-width: 160px;
            }
        }

        &.customerServiceSelectM2Long {
            .ant-select-selection {
                min-width: 280px;
            }
        }

        &.customerServiceSelectM3 {
            .ant-select-selection {
                min-width: 180px;
            }
        }

        &.customerServiceSelectM3Long {
            .ant-select-selection {
                min-width: 240px;
            }
        }


        &.ant-calendar-picker {
            position: fixed;
            opacity: 0;
            z-index: -9999;
            top: 30%;
            left: 40%;
            transform: translate(-50%, -45%);
        }

        .ant-calendar-picker-input.ant-input {
            padding-right: 24px !important;
        }

        .ant-select-selection {
            min-width: 150px;
        }

        .ant-select-selection {
            padding-left: 0;
            padding-right: 0;
            border: 1px solid #E3E3E3;
            border-radius: 8px;
            height: 100%;
            display: flex;
            align-items: center;

            .ant-select-selection-selected-value {
                margin-right: 10px;
            }

            .general {
                width: 25px;
                height: 30px;
                margin: 0 6px 0 4px;
            }

            .calendar1 {
                width: 20px;
                height: 20px;
                transform: translateX(10px);
            }

            .ant-select-selection__rendered {
                display: flex;
                align-items: center;
            }
        }
    }
}

.customerServiceSelectDropdownChild-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .2);
    z-index: 999;
}

.customerServiceSelectDropdown {
    background-color: #ffffff;

    // General Calender Picker style
    &.ant-calendar-picker-container {

        .ant-calendar-header {
            .ant-calendar-ym-select {
                pointer-events: none;
                /* 禁用点击 */
                cursor: default;
                /* 更改鼠标样式 */
            }
        }

        // 与M2 公共样式冲突， 单独拎出来 web
        .ant-calendar-body {

            // 被選定日期樣式
            .ant-calendar-cell.ant-calendar-selected-start-date.ant-calendar-selected-day,
            .ant-calendar-cell.ant-calendar-selected-end-date.ant-calendar-selected-day,
            .ant-calendar-cell.ant-calendar-selected-start-date.ant-calendar-last-day-of-month.ant-calendar-selected-day,
            .ant-calendar-cell.ant-calendar-today.ant-calendar-selected-end-date.ant-calendar-selected-day {
                .ant-calendar-date {
                    background-color: @colorType1 !important;
                    color: #fff !important;
                    border-radius: 999px !important;
                }
            }


            .ant-calendar-cell.ant-calendar-next-month-btn-day.ant-calendar-disabled-cell {
                display: none;
            }

            .ant-calendar-cell.ant-calendar-today.ant-calendar-next-month-btn-day {
                display: none;
            }

            .ant-calendar-cell.ant-calendar-today.ant-calendar-next-month-btn-day.ant-calendar-disabled-cell-first-of-row.ant-calendar-disabled-cell {
                display: none;

            }

            .ant-calendar-cell.ant-calendar-next-month-btn-day.ant-calendar-disabled-cell-last-of-row.ant-calendar-disabled-cell {
                display: none;
            }

            .ant-calendar-cell.ant-calendar-today {
                .ant-calendar-date {
                    color: @colorType1 !important;
                    font-weight: normal !important;
                    border-color: @colorType1;
                    color: #000 !important;
                    border-radius: 9999px !important;
                }
            }

            .ant-calendar-cell.ant-calendar-today.ant-calendar-disabled-cell {
                .ant-calendar-date {
                    background-color: #d8d8d8 !important;
                    border: none;
                    color: #999 !important;
                }
            }

            .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
                position: absolute;
                top: 0px;
                left: 8px;
                width: 24px;
                height: 24px;
                border: 1px solid #fff !important;
                border-radius: 1000px;
                content: ' ';
            }

            .ant-calendar-disabled-cell {
                .ant-calendar-date {
                    background-color: transparent; // #d8d8d8   https://arcadie.atlassian.net/browse/CUSP-55
                    color: #999999;
                    border-radius: unset;
                }
            }

            .ant-calendar-last-day-of-month+.ant-calendar-cell,
            .ant-calendar-disabled-cell-first-of-row {
                .ant-calendar-date {
                    border-radius: 20px 0 0 20px;
                }
            }

            .ant-calendar-disabled-cell-last-of-row,
            .ant-calendar-last-day-of-month {
                .ant-calendar-date {
                    border-radius: 0 20px 20px 0;
                }
            }

            .ant-calendar-last-day-of-month+.ant-calendar-cell.ant-calendar-disabled-cell-last-of-row,
            .ant-calendar-last-day-of-month.ant-calendar-disabled-cell-first-of-row {
                .ant-calendar-date {
                    border-radius: 20px 20px 20px 20px;
                }
            }

            .ant-calendar-last-month-cell,
            .ant-calendar-next-month-btn-day {
                .ant-calendar-date {
                    color: transparent;
                    background-color: #fff;
                }
            }
        }
    }

    .ant-calendar-range-part {
        .ant-calendar-header {

            .ant-calendar-prev-year-btn,
            .ant-calendar-next-year-btn {
                display: none !important;
            }

            .ant-calendar-prev-month-btn,
            .ant-calendar-next-month-btn {
                width: 24px;
                height: 24px;
                background: url(/cn/img/CustomerService/rightArrow.png) no-repeat;
                background-size: contain;
                top: 8px;

                &::before {
                    display: none;
                }
            }

            .ant-calendar-prev-month-btn {
                left: 15px !important;
                transform: rotate(180deg);
            }

            .ant-calendar-next-month-btn {
                right: 15px !important;
            }
        }
    }

    .ant-calendar-my-select,
    .ant-calendar-ym-select {
        a {
            color: #222 !important;
        }
    }

    .ant-calendar-selected-start-date,
    .ant-calendar-selected-end-date {
        position: relative;
        z-index: 99;

        &::before {
            background-color: #e6faff;
            content: "";
            width: 50%;
            display: block;
            position: absolute;
            top: 4px;
            bottom: 4px;
            z-index: -1;
        }
    }

    .ant-calendar-selected-start-date {
        &::before {
            left: 50%;
        }
    }

    .ant-calendar-selected-end-date {
        &::before {
            right: 50%;
        }
    }

    .ant-calendar-range {
        width: 600px;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
    }

    .selectOption {
        color: #222222;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        padding-top: 0;
        padding-bottom: 0;
    }

    .ant-calendar-footer.ant-calendar-range-bottom {
        border-top-width: 0px;
    }

    .ant-calendar-footer-extra {
        width: 100% !important;
    }

    &.ant-calendar-picker-container {
        border-radius: 16px;
        overflow: hidden;
        left: 50% !important;
        transform: translateX(-50%) !important;

        .ant-calendar-date-panel {
            position: relative;
            margin-top: 70px;

            .ant-calendar-range-middle {
                position: absolute;
                left: 0;
                right: 0;
                transform: translateX(0) !important;
                padding-right: 0px !important;
                color: #222;
            }


            .ant-calendar-range-left {
                .ant-calendar-input-wrap {
                    border-bottom-width: 0px !important;

                    .ant-calendar-date-input-wrap {
                        .ant-calendar-input {
                            text-align: right !important;
                            padding-right: 25px;
                        }
                    }
                }

            }

            .ant-calendar-range-right {
                .ant-calendar-input-wrap {
                    border-bottom-width: 0px !important;

                    .ant-calendar-date-input-wrap {
                        margin-left: 0px !important;

                        .ant-calendar-input {
                            text-align: left !important;
                            padding-left: 25px;
                        }
                    }
                }

            }
        }

        .RangePickerFooter {
            display: flex;
            justify-content: flex-end;
            padding-bottom: 15px;

            .rangePickerHeader {
                position: absolute;
                top: 0px;
                left: 0;
                right: 0;
                width: 100%;

                .pickerHeaderBox {
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px 20px 0;


                    .pickerHeaderTitle1 {
                        color: #222;
                        font-weight: 600;
                        font-size: 18px;
                    }


                    .CustomerServiceClose {
                        width: 24px;
                        height: 24px;
                        cursor: pointer;
                    }

                }



                .pickerHeaderTitle2 {
                    color: #666;
                    font-weight: 400;
                    font-size: 12px;
                    text-align: center;
                    line-height: 32px;
                }
            }



            .modalHeaderRightText {
                color: @colorType1;
                font-weight: 600;
                font-size: 16px;
                cursor: pointer;
            }

            .confrimTimeBtn {
                background-color: @colorType1;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 38px;
                margin-left: 15px;
                padding: 0 12px;
                cursor: pointer;
            }

            .confrimTimeBtnText {
                color: #fff;
                font-size: 16px;
                font-weight: 400;
            }
        }

    }
}


#CustomerServiceSpin.ant-spin {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding-top: 0px;
    z-index: 99999999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .2);

    &.hide {
        display: none;
        z-index: -999999999;
    }

    &.show {
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 99999999;
    }
}




.feedbackModalContainer {
    .viewContent {}

    .submission {
        width: 100%;
        margin-bottom: 15px;
        border-radius: 8px;
    }

    .fileBoxCom {
        border-width: 1px;
        border-style: solid;
        border-color: #E3E3E3;
        border-radius: 8px;
        padding: 0 15px;
        background-color: @backgroundColor;
        box-sizing: border-box;
    }

    .fileBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 44px;
    }

    .fileInputBox {
        width: 100%;
        height: 200px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 5px;
    }

    .fileTitleBox {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .fileTitle {
        color: #222;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    .fileText {
        color: #BCBEC3;
        font-size: 14px;
        font-weight: 400;
    }

    .downArrow {
        margin-top: -4px;
        border-bottom-color: #BCBEC3;
        border-left-color: #BCBEC3;
        transform: rotate(-45deg);
    }

    .fileTitleIcon {
        color: #83630B;
        font-size: 10px;
        font-weight: 500;
        background-color: #FFF5BF;
        padding: 4px;
        border-radius: 8px;
        margin-left: 5px;
        overflow: hidden;
        margin-bottom: 10px;
    }

    .deleteImgBox {
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 99;
    }

    .deleteImg {
        width: 32px;
        height: 32px;
    }

    .uploadFileBytesImg {
        width: 100%;
        height: 140px;
        overflow: hidden;
        margin-bottom: 10px;
        border-radius: 8px;
        position: relative;
    }


    .uploadBtn {
        display: flex;
        flex-direction: row;
        height: 44px;
        align-items: center;
        justify-content: center;
        border-width: 1px;
        border-color: @colorType1;
        border-radius: 8px;
        margin-bottom: 10px;
        border-style: solid;
        cursor: pointer;
        position: relative;
    }

    .uploadImg {
        width: 24px;
        height: 24px;
        margin-right: 6px;
    }

    .uploadText {
        color: @colorType1;
        font-size: 16px;
        font-weight: 400;
    }

    .uploadTipList {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2px 0;
    }

    .uploadTipCirlce {
        background-color: #222;
        width: 5px;
        height: 5px;
        border-radius: 1000px;
        overflow: hidden;
        margin-right: 5px;
    }

    .uploadTipText {
        color: #222222;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 6px;
    }

    .submitBtn {
        background-color: #E3E3E3;
        height: 44px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 100px;
        cursor: pointer;
    }

    .submitBtnText {
        color: #999;
        font-size: 16px;
        font-weight: 400;
    }

    .viewBox {
        margin-bottom: 15px;
    }

    .inputLimitBox {
        text-align: right;
    }

    .inputLimitText {
        color: #999;
        font-size: 12px;
        font-weight: 400;
    }
}