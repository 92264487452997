.toast2025 {
    position: fixed;
    z-index: 99999;
    top: 170px;
    left: 50%;
    height: 40px;
    transform: translateX(-50%);
    color: #666;
    font-size: 14px;
    padding: 10px 16px 10px 36px;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    pointer-events: none;
    opacity: 0;
    transition: top 0.5s ease-out, opacity 0.5s ease-out;

    &.show {
        top: 200px;
        opacity: 1;
    }

    &.OK,
    &.ERR,
    &.WARN {
        background: url(/cn/img/1wallet/ui/ok.svg) #fff no-repeat 14px 56%;
    }

    &.ERR {
        background-image: url(/cn/img/1wallet/ui/err.svg);
    }

    &.WARN {
        background-image: url(/cn/img/1wallet/ui/warn.svg);
    }
}
