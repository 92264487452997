.whoisambassador-introduce-layout {
    background-color: #0c0a30;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 10;
    .whoisambassador-banner-image {
        position: relative;
        width: 100%;
        z-index: -1;
        transform: translateY(-90px);
    }
    .whoisambassador-wrapper {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 90px;
        color: #FFE4C4;
        .whoisambassador-introduction-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            &-title {
                text-align: center;
                font-weight: 500;
                font-size: 35px;
                line-height: 49px;
                margin-bottom: 10px;
            }
            &-desc {
                display: flex;
                justify-content: space-around;
                gap: 185px;
                margin-bottom: 70px;
            }
            &-content {
                display: flex;
                flex-direction: column;
                gap: 35px;
                margin-top: 70px;
                &-title {
                    font-size: 35px;
                    line-height: 49px;
                    font-weight: 500;
                }
                .whoisambassador-hint {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    font-size: 24px;
                    img {
                        width: 37.37px;
                    }
                }
            }
            &-pred {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                width: 343px;
                height: 44px;
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                color: white;
                background-color: rgba(0, 166, 255, 1);
                border-radius: 6px;
            }
        }
        .whoisambassador-video-section {
            display: flex;
            flex-direction: column;
            gap: 30px;
            align-items: center;
            .whoisambassador-video-section-title {
                font-size: 35px;
                font-weight: 600;
                line-height: 49px;
            }
        }
        .whoisambassador-promotion-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            margin-bottom: 80px;
            &-title {
                font-size: 35px;
                font-weight: 600;
                line-height: 49px;
                margin-bottom: 20px;
            }
            img {
                cursor: pointer;
            }
        }
    }
}