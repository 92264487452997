@keyframes PhotoView__rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes PhotoView__delayShow {
    0%,
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.PhotoView__Spinner {
    animation: PhotoView__delayShow 0.4s linear both;
}
.PhotoView__Spinner svg {
    animation: PhotoView__rotate 0.6s linear infinite;
}

.PhotoView__Photo {
    will-change: transform;
    cursor: grab;
}
.PhotoView__Photo:active {
    cursor: grabbing;
}

@keyframes PhotoView__animateIn {
    from {
        opacity: 0.4;
        transform: scale(0.2);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes PhotoView__animateOut {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.2);
    }
}
.PhotoView__animateIn {
    opacity: 0.4;
    animation: PhotoView__animateIn 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) both;
}
.PhotoView__animateOut {
    opacity: 1;
    animation: PhotoView__animateOut 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) both;
}
.PhotoView__PhotoWrap {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow: hidden;
}
.PhotoView__PhotoMask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.PhotoView-SlideWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    overflow: hidden;
}

@keyframes PhotoView__fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.PhotoView-PhotoSlider__Backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: -1;
}
.PhotoView-PhotoSlider__fadeIn {
    opacity: 0;
    animation: PhotoView__fade 0.4s linear both;
}
.PhotoView-PhotoSlider__fadeOut {
    opacity: 0;
    animation: PhotoView__fade 0.4s linear both reverse;
}
.PhotoView-PhotoSlider__BannerWrap {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 44px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.2s ease-out;
    z-index: 20;
}
.PhotoView-PhotoSlider__Counter {
    padding: 0 10px;
    font-size: 14px;
    opacity: 0.75;
}
.PhotoView-PhotoSlider__BannerRight {
    height: 100%;
}
.PhotoView-PhotoSlider__Close {
    box-sizing: border-box;
    padding: 10px;
    opacity: 0.75;
    cursor: pointer;
    transition: opacity 0.2s linear;
}
.PhotoView-PhotoSlider__Close:hover {
    opacity: 1;
}
.PhotoView-PhotoSlider__FooterWrap {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 10px;
    width: 100%;
    min-height: 44px;
    line-height: 1.5;
    font-size: 14px;
    color: #ccc;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: justify;
    transition: opacity 0.2s ease-out;
    z-index: 20;
}
