.email-verification {
    display: flex;
    justify-content: center;

    .background-img {
        margin-top: 60px;
        margin-bottom: 100px;
        background: url(/cn/img/emailverification/background-img.png) center center no-repeat;
        background-size: cover;
        border-radius: 20px;
        width: 960px;
        height: 480px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .content {
            padding: 30px 20px;
            background-color: #ffffff;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 360px;
            margin-right: 60px;

            .title {
                color: #222222;
                font-size: 18px;
                font-weight: 400;
                margin-top: 30px;
            }

            .description {
                color: #222222;
                font-size: 14px;
                font-weight: 400;
                margin-top: 7px;
                margin-bottom: 18px;
            }

            button {
                margin-top: 10px;
            }
        }
    }
}