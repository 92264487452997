.register-success {
    background-image: url(/cn/img/bonus/BG.png);
    background-position: center 0;

    .white-btn {
        border: 1px solid #cccccc;
        background: white 0% 0% no-repeat padding-box;
        border-radius: 8px;
        width: 100%;
        padding: 7px;
        color: #222222;
        font-size: 15px;
        cursor: pointer;
    }

    .next-btn {
        background: #f92d2d 0% 0% no-repeat padding-box;
        border-radius: 8px;
        width: 100%;
        padding: 7px;
        color: white;
        font-size: 15px;
        border: 0;
        margin: 80px auto 0;
        cursor: pointer;
    }

    .form {
        font-weight: bold;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 8px #00000014;
        border-radius: 10px;
        width: 360px;
        padding: 20px 25px;
        position: relative;
        .left {
            width: 82px;
            height: 222px;
            position: absolute;
            left: 5px;
            top: 57px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .right {
            width: 109px;
            height: 194px;
            position: absolute;
            right: 5px;
            top: 75px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .Bonus-box {
        height: 460px;
        margin-bottom: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: 100%;
        border-radius: 20px;
    }

    .box_body {
        height: auto;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 20px;
        margin-top: 25px;
        margin-bottom: 50px;
        padding: 5px 15px;
        min-height: 300px;
    }

    .register-bonus {
        width: 200px;
        border-radius: 6px;
        background-color: #f0f0f0;
        border: 2px solid #e5e5e5;
        margin: 8px auto;
        text-align: left;
        padding: 8px 0 8px 16px;
        line-height: 16px;
        font-size: 12px;
        color: #000;
        font-weight: normal;
        position: relative;
        h3 {
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 0;
            color: #000;
            text-shadow: 0 0 #000;
        }
        img {
            width: 27px;
            height: 30px;
            position: absolute;
            top: 27px;
            right: 60px;
        }
    }

    h2 {
        text-align: center;
        font-weight: bold;
    }
}
