.left-next,
.right-next {
    top: 0;
    bottom: 0;
    width: 36px;
    height: 100px;
    margin: auto;
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    //background-color: #676767;
    z-index: 1;
    img {
        width: 32px;
        height: 64px;
    }
}

.left-next {
    left: 22px;
}

.right-next {
    right: 22px;
}
.lesson-box {
    .ant-tabs-nav .ant-tabs-tab{
        margin: 0 20px 0 0;
    }
    .ant-carousel .custom-Carousel .slick-dots li.slick-active button{
        background-color: @theme;
    }
}
.crypto-lesson-wrap {
    position: relative;
    width: 100%;
    height: 524px;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    &.huobi-ls-1 {
        background-image: url(/cn/img/usdt/learn/huobi_1.png);
    }
    &.huobi-ls-2 {
        background-image: url(/cn/img/usdt/learn/huobi_2.png);
    }
    &.huobi-ls-3 {
        background-image: url(/cn/img/usdt/learn/huobi_3.png);
    }
    &.huobi-ls-4 {
        background-image: url(/cn/img/usdt/learn/huobi_4.png);
    }
    &.huobi-ls-5 {
        background-image: url(/cn/img/usdt/learn/huobi_5.png);
    }
    &.bian-ls-1 {
        background-image: url(/cn/img/usdt/learn/bian_1.png);
    }
    &.bian-ls-2 {
        background-image: url(/cn/img/usdt/learn/bian_2.png);
    }
    &.bian-ls-3 {
        background-image: url(/cn/img/usdt/learn/bian_3.png);
    }
    &.bian-ls-4 {
        background-image: url(/cn/img/usdt/learn/bian_4.png);
    }
    &.bian-ls-5 {
        background-image: url(/cn/img/usdt/learn/bian_5.png);
    }
    &.bian-ls-6 {
        background-image: url(/cn/img/usdt/learn/bian_6.png);
    }
    &.okex-ls-1 {
        background-image: url(/cn/img/usdt/learn/okex_1.png);
    }
    &.okex-ls-2 {
        background-image: url(/cn/img/usdt/learn/okex_2.png);
    }
    &.okex-ls-3 {
        background-image: url(/cn/img/usdt/learn/okex_3.png);
    }
    &.okex-ls-4 {
        background-image: url(/cn/img/usdt/learn/okex_4.png);
    }
    &.im-ls-1 {
        background-image: url(/cn/img/usdt/learn/im_1.png);
    }
    &.im-ls-2 {
        background-image: url(/cn/img/usdt/learn/im_2.png);
    }

    &.rapid-ls-1 {
        background-image: url(/cn/img/depositlesson/jisuxunibi_1.jpg?v2);
    }
    &.rapid-ls-2 {
        background-image: url(/cn/img/depositlesson/jisuxunibi_2.jpg?v2);
    }
    &.rapid-ls-3 {
        background-image: url(/cn/img/depositlesson/jisuxunibi_3.jpg?v2);
    }
    &.rapid-ls-4 {
        background-image: url(/cn/img/depositlesson/jisuxunibi_4.jpg?v2);
    }

    &.general-ls-1 {
        background-image: url(/cn/img/depositlesson/xnb1-1.jpg?v2);
    }
    &.general-ls-2 {
        background-image: url(/cn/img/depositlesson/xnb1-2.jpg?v2);
    }
    &.general-ls-3 {
        background-image: url(/cn/img/depositlesson/xnb1-3.jpg?v2);
    }
    &.general-ls-4 {
        background-image: url(/cn/img/depositlesson/xnb1-4.jpg?v2);
    }

    &.aut-ls-1 {
        background-image: url(/cn/img/depositlesson/xnb2-1.jpg?v2);
    }
    &.aut-ls-2 {
        background-image: url(/cn/img/depositlesson/xnb2-2.jpg?v2);
    }
    &.aut-ls-3 {
        background-image: url(/cn/img/depositlesson/xnb2-3.jpg?v2);
    }

    each(range(12), {
    &.coinwin-ls-@{value} {
        background-image: url("/cn/img/depositlesson/CoinW-Desktop_Tutorial-@{value}.jpg?v2");
    }
    });
    each(range(11), {
    &.firecoin-@{value} {
        background-image: url("/cn/img/depositlesson/Huobi-Desktop_Tutorial-@{value}.jpg?v2");
    }
    });
    each(range(8), {
    &.okxcoin-ls-@{value} {
        background-image: url("/cn/img/depositlesson/OKX-Desktop_Tutorial-@{value}.jpg?v2");
    }
    });
    each(range(6), {
    &.coinsafe-ls-@{value} {
        background-image: url("/cn/img/depositlesson/Binance-Desktop_Tutorial-@{value}.jpg?v2");
    }
    });
    each(range(6), {
        &.sts-@{value} {
            background-image: url("/cn/img/depositlesson/sts-@{value}.jpg");
        }
    });
    each(range(4), {
        &.ny-@{value} {
            background-image: url("/cn/img/depositlesson/ny-@{value}.jpg");
        }
    });

}

.ant-tabs-tab {
    color: #999999;
}

.ant-tabs-nav .ant-tabs-tab-active {
    font-weight: 1000 !important;
    color: #222222 !important;
}

.crypto-lesson-body {
    margin-left: 6rem;
    margin-right: 6rem;
}

.deposit-comment-title {
    text-align: left;
    font-weight: 1000;
    font-size: 14px;
    margin-top: 25px;
    margin-bottom: 10px;
    min-height: 16px;
    margin-left: 10px;
}

.deposit-comment-content {
    text-align: left;
    font-size: 12px;
    min-height: 16px;
}

.custom-Carousel {
    .slick-dots {
        bottom: -5px !important;
        z-index: 999;
        button {
            margin-right: 5px;
        }
    }
}

.ant-carousel .custom-Carousel .slick-dots li.slick-active {
    background: transparent;
}

.ant-carousel .custom-Carousel .slick-dots li.slick-active button {
    background: #000000bf;
    border-radius: 50%;
    height: 8px;
    width: 8px;
}

.ant-carousel .custom-Carousel .slick-dots li {
    background: transparent;
}

.ant-carousel .custom-Carousel .slick-dots li button {
    background: #00000040;
    border-radius: 50%;
    height: 8px;
    width: 8px;
}

.arrowLeftRight {
    background-color: #00000040;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
}

.comment-title {
    text-align: center;
    color: #f92d2d;
    font-size: 18px;
    font-weight: 1000;
    font-family: Microsoft YaHei;
    padding-top: 2rem;
}

.comment-content {
    text-align: left;
    color: #000000;
    font-weight: 500;
    font-family: Microsoft YaHei;
    font-size: 16px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 10rem;
    margin-right: 10rem;
    line-height: 20px;
    text-shadow: 0.2px 0.2px, 0.2px 0.2px, 0.2px 0.2px;
}

.deposit-comment-button,
.deposit-comment-button:active,
.deposit-comment-button:focus {
    background-color: #f92d2d;
    text-align: center;
    color: white;
    border-radius: 15px;
    border: #f92d2d solid 1px;
    font-size: 14px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.titleHeader {
    background-color: #00a6ff;
    color: white;
}
