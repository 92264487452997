.DailyGiftAddress {
    width: 400px !important;

    .ant-modal-content {
        border-radius: 20px;

        .ant-modal-header {
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;

            .ant-modal-title {
                color: #fff;
            }
        }

        .ant-modal-body {
            padding-bottom: 30px;
            display: flex;
            flex-direction: column;
        }
    }

    .DailyGiftAddress-info {
        margin: 24px auto;
        width: 343px;
        height: 80px;
        /* UI Properties */
        background: #fff5bf 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
        padding: 12px 16px;
        text-align: left;
        font: normal normal bold 12px/20px Microsoft YaHei;
        letter-spacing: 0px;
        color: #83630b;
    }
    .DailyGiftAddress-list {
        display: flex;
        align-items: center;
        width: 340px;
        height: 134px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #cccccc;
        border-radius: 10px;
        opacity: 1;
        .radioBox {
            margin-left: 20px;
            width: 20px;
            height: 20px;
        }
        .DailyGiftAddress-list-info {
            position: relative;
            margin-left: 20px;
            width: calc(100% - 130px);
            white-space: nowrap;
            label {
                text-align: left;
                font: normal normal normal 12px/16px Microsoft YaHei;
                letter-spacing: 0px;
                color: #666666;
                opacity: 1;
            }
            span {
                margin-left: 10px;
                text-align: left;
                font: normal normal normal 12px/16px Microsoft YaHei;
                letter-spacing: 0px;
                color: #222222;
                opacity: 1;
            }
            .editBox {
                position: absolute;
                cursor: pointer;
                width: 16px;
                height: 16px;
                top: 0;
                right: 0;
            }
            div:first-child,
            div:nth-child(3) {
                display: flex;

                span {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: block;
                }
            }
        }
        .default {
            position: absolute;
            right: 0;
            bottom: 0;
            font: normal normal normal 10px/14px Microsoft YaHei !important;
            color: #00a6ff !important;
        }
    }
    .DailyGiftAddress-btn {
        display: flex;
        justify-content: center;
        // margin-bottom: 10px;

        button {
            letter-spacing: -2px;
            width: 340px;
            height: 40px;
            padding: 10px 0;
            border: 1px solid var(--unnamed-color-00a6ff);
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #00a6ff;
            border-radius: 10px;
            opacity: 1;
            font: normal normal normal 14px/22px Microsoft YaHei;
            letter-spacing: 0px;
            color: #00a6ff;
            cursor: pointer;
        }
    }
    .DailyGiftAddress-remark {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 340px;
        margin-bottom: 30px;
        label {
            margin-top: 10px;
            margin-bottom: 10px;
            color: #222222;
            opacity: 1;
            font-size: 14px;
            font-family: PingFangSC-Regular;
        }
    }
    .DailyGiftAddress-submit {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        // margin-bottom: 30px;
        button {
            width: 340px;
            height: 40px;
            padding: 10px 0;
            background: #00a6ff 0% 0% no-repeat padding-box;
            border-radius: 8px;
            opacity: 1;
            font: normal normal normal 14px/22px MicrosoftYaHei-Bold;
            font-weight: bold;
            letter-spacing: -2px;
            color: #ffffff;
            cursor: pointer;
        }
    }
}
