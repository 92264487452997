.ddown-wrap {
    ul li:hover {
        background:#f5f5f5
    }

    button img {
        transition: transform 0.5s ease;
    }
    &.ddown-wrap-open button img {
        transform: rotate(180deg);
    }
}
