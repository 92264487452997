.Selfexclusion-content {
    width: 1120px;
    padding: 138px 46px 85px 74px;
    background: url(/cn/img/selfexclusion/bg.png) center center no-repeat;
    height: 655px;
    background-size: 100%;
    margin-bottom: 50px;

    button {
        width: 127px;
        height: 40px;
        line-height: 35px;
        background: #00a6ff;
        border: 0;
        opacity: 1;
        border-radius: 8px;
        cursor: pointer;
        color: white;
        &:disabled {
            background-color: #ccc;
        }
    }

    .ant-input-number {
        width: 354px;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        opacity: 0.6;
    }

    // .form-submit div {
    // 	height: 100%;
    // 	display: flex;
    // 	justify-content: center;
    // 	align-items: flex-start;
    // 	flex-flow: column;
    // 	color: #222;
    // 	line-height: 45px;
    // }

    .form-submit .l {
        flex: 1;
        display: flex;
        flex-flow: column;
    }

    .form-submit .c {
        flex: 1;
        display: flex;
        flex-flow: column;
    }

    .form-submit .r {
        flex: 0.5;
        display: flex;
        justify-content: flex-end;
    }

    .form-submit {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 185px;
        margin-top: 7px;
        background-color: white;
        color: #222;
        padding: 20px 64px 20px 35px;
        border-radius: 20px;
        // line-height: 50px;
        margin-left: 5px;

        .l,
        .c {
            gap: 15.5px;
            label {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 16px;

                input {
                    width: 20px;
                    height: 20px;
                    &::after {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                        border: 2px solid #bdbdbd;
                        border-radius: 100%;
                    }
                    &:checked::after {
                        background-color: white;
                        border: 5px solid #00a6ff;
                    }
                }
            }

            .ant-input,
            .ant-select-selection {
                border-color: #e0e0e0;
                &::placeholder {
                    color: #999;
                }
            }

            .ant-select-selection {
                width: 316px;
                height: 45px;
                .ant-select-selection__rendered {
                    padding-top: 13px;
                    .ant-select-selection__placeholder {
                        color: #999;
                    }
                    .ant-select-selection-selected-value {
                        line-height: normal;
                    }
                }
            }

            & span:last-child {
                font-size: 12px;
                margin-left: 15px;
            }
        }
    }

    .About_Search {
        width: 200px;
        padding-bottom: 10px;
    }

    .box_body {
        height: auto;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 20px;
        margin-top: 25px;
        margin-bottom: 50px;
        padding: 5px 15px;
        min-height: 300px;
    }

    h2 {
        font-weight: bold;
    }

    .txtbox {
        width: 480px;
        height: 280px;
        padding: 76px 30px 85px 20px;
        background: url(/cn/img/selfexclusion/Group561.png) center center
            no-repeat;
        font-size: 12px;

        p {
            width: 423px;
            font-size: 14px;
        }
    }
}

.selfExclusion-selectDays {
    &.ant-select-disabled {
        img {
            opacity: 0.5;
        }
    }
}

.selfExclusion-selectDays-dropdown {
    .ant-select-dropdown-menu {
        .ant-select-dropdown-menu-item {
            display: flex;
            justify-content: space-between;
            transition: none;
        }
        .ant-select-dropdown-menu-item-selected {
            font-weight: normal;
            background-color: transparent;
            background-image: url("/cn/img/selfexclusion/icon-add.png");
            background-repeat: no-repeat;
            background-position-x: 287px;
            background-position-y: 16px;
        }
        & li:first-child,
        & li:nth-child(2) {
            border-bottom: 1px solid #e0e0e0;
        }
    }
}

.SelfExclusionModal {
    .ant-modal-content {
        border-radius: 20px;
    }
    .ant-modal-header {
        height: 60px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .ant-modal-title {
            color: white;
        }
    }
    .ant-modal-body {
        padding: 30px;
        .SelfExclusionModalButton {
            width: 100%;
            height: 40px;
            margin-top: 29px;
            color: white;
            border: none;
            background-color: #00a6ff;
        }
    }
}
