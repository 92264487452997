.has-auto-suggest-lst {
    position:relative;
}
.auto-suggest-lst {
    display:none;
    position:absolute;
    top:75px;
    z-index:1;
    width:100%;
    padding:8px 0;
    background:#fff;
    border:1px solid #e3e3e3;
    border-radius:8px;
    li {
        padding:8px 16px;
        font-size:14px;
        color:#222;
        cursor:pointer;
    }
    li:hover {
        background: #EFEFF4;
    }
}
